<div class="crm-container">
    <h2 class="title"> <b>{{ tittle }}</b> </h2>

    <div class="tables-container">
        <!-- Primera tabla -->
        <div class="table-section">
            <table>
                <thead>
                    <tr>
                        <th>MODELO</th>
                        <th>LEADS <br> PROYECTADOS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let modelo of reporte_modelos">
                        <td>{{ modelo.name }}</td>
                        <td>
                            <input type="number" 
                            [value]="modelo.cantidad" 
                            [(ngModel)]="modelo.cantidad" 
                            (ngModelChange)="actualizarCantidad($event, modelo)">
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td class="bg-grease">TOTAL</td>
                        <td class="bg-grease">{{ getTotalProyectado() }}</td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <!-- Segunda tabla -->
        <div class="table-section">
            <table>
                <thead>
                    <tr>
                        <th>MENSAJES <br> ENVIADOS</th>
                        <th>LEADS <br> OBTENIDOS</th>
                        <th>% <br> CONVERSIÓN</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let modelo of reporte_modelos">
                        <td>{{ modelo.mensajes_enviados }}</td>
                        <td>{{ modelo.leads_obtenidos }}</td>
                        <td>{{ modelo.conversion | number: '1.2-2' }}%</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td class="bg-grease">{{ getTotalMensajesEnviados() }}</td>
                        <td class="bg-grease">{{ getTotalLeadsObtenidos() }}</td>
                        <td class="bg-grease">{{ getTotalConversion() | number: '1.2-2' }}%</td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <!-- Tercera tabla -->
        <div class="table-section">
            <table>
                <thead>
                    <tr>
                        <th>CPL</th>
                        <th>% LOGRO</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let modelo of reporte_modelos">
                        <td>${{ modelo.cpl | number: '1.2-2' }}</td>
                        <td [ngClass]="getClassForValue(modelo.logro)">{{ modelo.logro | number: '1.2-2' }} %</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr >
                        <td class="bg-grease">${{ getTotalCPL() | number: '1.2-2' }}</td>
                        <td class="bg-grease">{{ getTotalLogro() | number: '1.2-2' }}%</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>




<table class="table table-lead table-border mt-2" *ngIf="Object.keys(reporte_leads).length > 0">
    <thead class="thead-honda">
      <tr>
        <th class="text-center" ></th>
        <ng-container *ngFor="let modelo of modelos">
          <th  class="text-center table-left table-rigth table-bottom"  [attr.colspan]="colapsan_value" >{{modelo}}</th>
        </ng-container>
        <th class="text-center " colspan="2"  > Total </th>

      </tr>
      <tr>
        <th class="text-center" >Fecha</th>
        <ng-container *ngFor="let modelo of modelos">
          <ng-container *ngFor="let tipo of tipos">
            <th class="text-center table-left" >BBDD <br> {{tipo}}</th>
            <th class="text-center table-rigth"  >Leads</th>
          </ng-container>
        </ng-container>
        <th class="text-center table-left" >SMS</th>
        <th class="text-center table-rigth"  >Leads</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let fecha of Object.keys(reporte_leads)">
        <td class="text-center" >{{ fecha | date: 'd/M' }}</td>
     
        <ng-container *ngFor="let modelo of modelos">
            <ng-container  *ngFor="let tipo of tipos" >
                <td class="text-center table-left" >
                    {{getValue(fecha, modelo, tipo)}} 
                </td>
                <td class="text-center table-secondary table-rigth"  >
                    {{getLeads(fecha, modelo, tipo)}} 
                </td>
            </ng-container>
       
        </ng-container>

     
        <td class="text-center table-secondary table-rigth"  >
            {{getTotalCantidadByDate(fecha)}} 
        </td>
        <td class="text-center table-left" >
            {{getTotalLeadsByDate(fecha)}} 
        </td>
      
      </tr>
        
      <tr>
        <td class="text-center" >TOTAL</td>
        <ng-container *ngFor="let modelo of modelos">
            <ng-container  *ngFor="let tipo of tipos" >
                <td class="text-center table-warning table-left" >
                    {{calcularTotalModelo(modelo, tipo)}} 
                </td>
                <td class="text-center table-secondary table-rigth" >
                    {{getTotalLeadsModelo(modelo, tipo)}} 
                </td>
            </ng-container>
        </ng-container>

        <td class="text-center table-warning table-left" >
            {{calcularTotal()}} 
        </td>
        <td class="text-center table-secondary table-rigth" >
            {{getTotalLeads()}} 
        </td>

      </tr> 
    </tbody> 
</table>
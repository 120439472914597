<div class="container">
    <div class="form-group m-5">
        <h2>SEGUIMIENTO DE ACTIVIDAD DEL USUARIO</h2>
        <p>Registro detallado de las interacciones pasadas del usuario en la web </p>
    </div>

    <div class="form-group m-5">
        <p>Leyenda: </p>
        <div class="row">

            <span class="col-2 badge rounded-pill bg-utm">  Modelo</span>
            <span class="col-2 badge rounded-pill bg-lead">  Lead Exitoso </span>
        </div>
        
    </div>
            
    <div class="row mt-4">
        <div class="col-2"> FECHA </div>
        <div class="col-1">  HORA </div>
        <div style="overflow:hidden" class="col-4"> RUTA </div>
        <div style="overflow:hidden" class="col-2"> Modelo </div>
        <div style="overflow:hidden" class="col-3"> UTMS </div>
    </div>

    <div class="row">
        <ul class="list-group">
            <li *ngFor="let listado of listado_acciones" class="list-group-item {{listado.background}}" >
                <div class="row">
                    <div class="col-2">{{obtenerFecha(listado.created_time)}}</div>
                    <div class="col-1">{{obtenerHora(listado.created_time)}}</div>
                    <div style="overflow:hidden" class="col-4">{{sindominio(listado.link)}}</div>
                    <div  class="col-2">{{listado.modelo}}</div>
                    <div class="col-3">
                        <p [innerHTML]="listado.utms"></p>
                    </div>
                </div>
            </li>
        </ul>
    </div>

</div>
<app-breadcrumb [title]="'Campañas Whatsapp'" [items]="['Campañas']"></app-breadcrumb>

<div *ngIf="reporte" class="card-body">
    <table class="table table-bordered">
      <thead  class="thead-honda">
        <tr>
          <th class="text-center" title="Nombre de la campaña"></th>
          <th class="text-center" title="Cantidad de mensajes o correos enviados"  placement="top" 
          [container]="'body'" ngbTooltip="Cantidad de envios realizados durante las fechas en cuestión (filtro)">Cantidad</th>
          <th class="text-center" title="Audiencia total de la campaña"  placement="top" 
          [container]="'body'" ngbTooltip="Total de la audiencia seleccionada al momento de generarla">Audiencia</th>
          <th class="text-center" title="Mensajes enviados en la campaña"  placement="top" 
          [container]="'body'" ngbTooltip="Total de envíos satisfactorios enviados">Enviados</th>
          <th class="text-center" title="Mensajes que rebotaron"  placement="top" 
          [container]="'body'" ngbTooltip="Total de rebotes ">Rebotes</th>
          <th class="text-center" title="Número de aperturas">Leido N</th>
          <th class="text-center" title="Porcentaje de aperturas">Leido %</th>
          <th class="text-center" title="Número de clics">Click N</th>
          <th class="text-center" title="Porcentaje de clics">Click %</th>
          <th class="text-center" title="Número de leads generados">Leads</th>
          <th class="text-center" title="Efectividad de la campaña">Efectividad</th>
        </tr>    
      </thead>
      <tbody>
        <tr *ngFor="let r of reporte" [ngClass]="{ 'table-danger': r.Nombre === 'Total' }">
            <td class="text-center table-danger" >{{ r.Nombre }}</td>
            <td class="text-center ">{{ r.Cantidad }}</td>
          <td class="text-center">{{ formatNumber(r.Audiencia) }}</td>
          <td class="text-center">{{ formatNumber(r.Enviados) }}</td>
          <td class="text-center">{{ formatNumber(r.Rebotes) }}</td>
          <td class="text-center">{{ formatNumber(r['Leido N']) }}</td>
          <td class="text-center">{{ formatDecimal(r['Leido %']) }} %</td>
          <td class="text-center">{{ formatNumber(r['Click N']) }}</td>
          <td class="text-center">{{ formatDecimal(r['Click %']) }} %</td>
          <td class="text-center">{{ formatNumber(r.Leads) }}</td>
          <td class="text-center">{{ formatDecimal(r.Efectidad) }} %</td>
        </tr>
      </tbody>
    </table>
</div>
  

<div class="row resumen-leads">



    <div class="col-4">
        <div class="card o-hidden">
            <div class="bg-saldo b-r-4 card-body text-white">
                <div class="media static-top-widget">
                    <div class="media-body">
                        <span class="nombre-estado-cliente m-0">
                            {{ tituloSaldo }}
                        </span>
                        <h5 class="number-saldo mb-0 counter" CountTo="" from="0" duration="1">
                            {{ formatDecimal(saldo) }} {{ moneda }}  
                            <!-- Icono de ojo visible cuando el título es 'Saldo Actual Twilio' -->
                            <i *ngIf="tituloSaldo == 'Saldo Actual Twilio'" class="fa fa-eye" (click)="verSaldoReal()"></i>
                            <!-- Icono de ojo tachado visible cuando el título es 'Saldo' -->
                            <i *ngIf="tituloSaldo == 'Saldo'" class="fa fa-eye-slash" (click)="verSaldoReal()"></i>
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    
    
    

    <div class="col-4">
        <div class="card o-hidden">
            <div class="bg-saldo b-r-4 card-body text-white">
                <div class="media static-top-widget">
                    <div class="media-body"><span
                        class="nombre-estado-cliente m-0"> Cuanto vas gastando </span>
                        <h5 class="number-saldo mb-0 counter"
                            CountTo="" from="0"
                            duration="1">  {{formatDecimal(costo_total)}} {{moneda}}
                        </h5>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="col-4">
        <div class="card o-hidden">
            <div class="bg-saldo b-r-4 card-body text-white">
                <div class="media static-top-widget">
                    <div class="media-body row ">
                        <span class="nombre-estado-cliente m-0">Saldo del Mes</span>
                        <h5 class="number-saldo mb-0 counter" CountTo="" from="0" duration="1">
                             {{formatDecimal(saldo_mes)}} {{moneda}} <i  class="fa fa-pencil" (click)="editarSaldo()" ></i>

                        </h5>
                      
                    </div>
                   

                </div>
            </div>
        </div>
    </div>
</div>

<div id="mensaje-costo">
    El costo total de las campañas se calcula exclusivamente en función de las fechas seleccionadas.
</div>



<div class="col-12">
    <div class="card-body card-body-filtros">
        <div class="buttons">
            <button type="button" class="btn btn-primary-honda button_filter__collapse"
                (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
                aria-controls="collapseExample">
                <span>Filtros</span>
                <app-feather-icons [icon]="'filter'"></app-feather-icons>

            </button>
        </div>
        <div id="collapseExample" [ngbCollapse]="isCollapsed">
            <div class="card mb-0">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12">
                            <h5><u>Filtros </u></h5>
                        </div>

                        <!-- Filtro por Mes -->
                        <div class="col-12 col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Mes y Año</label>
                                <div class="col-sm-9">
                                    <input class="form-control"
                                           [(ngModel)]="filtros.mesAnio"
                                           id="month-year-input"
                                           type="month"
                                           (change)="updateCalendar()"
                                           >
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6"></div>
                        <br>

                        <div class="col-12 col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Fecha de
                                    inicio</label>
                                <div class="col-sm-9">
                                    <input class="form-control digits"
                                        [(ngModel)]="filtros.fecha_inicio"
                                        id="example-datetime-local-input" type="date" [attr.min]="fechaMin"
                                        [attr.max]="fechaMax"
                                 >
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Fecha fin</label>
                                <div class="col-sm-9">
                                    <input class="form-control digits"
                                        [(ngModel)]="filtros.fecha_fin"
                                        id="example-datetime-local-input" type="date" [attr.min]="fechaMin"
                                        [attr.max]="fechaMax"
                                 >
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Fecha de inicio Lanzamiento Campaña</label>
                                <div class="col-sm-9">
                                    <input class="form-control digits"
                                        [(ngModel)]="filtros.fecha_lanzamiento_inicio"
                                        id="example-datetime-local-input" type="date" [attr.min]="fechaMin"
                                        [attr.max]="fechaMax"
                                 >
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label">Fecha Fin Lanzamiento Campaña </label>
                                <div class="col-sm-9">
                                    <input class="form-control digits"
                                        [(ngModel)]="filtros.fecha_lanzamiento_fin"
                                        id="example-datetime-local-input" type="date"
                                        [attr.min]="fechaMin"
                                        [attr.max]="fechaMax"
                                 >
                                </div>
                            </div>
                        </div>

                   
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="col-form-label">Tipo Campaña</div>

                              

                                <ng-select [items]="default_values.type"
                                    [(ngModel)]="filtros.type"
                                    class="js-example-basic-multiple" placeholder=""
                                    [multiple]="true" bindLabel="name" bindValue="id">
                                </ng-select>

                                
                            </div>
                        </div>
                        
                        <!--
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="col-form-label">Tipo de filtro</div>
                                <ng-select [items]="default_values.tipo_filtro"
                                    class="js-example-basic-multiple" placeholder=""
                                    [(ngModel)]="filtros.tipo_filtro" [multiple]="false">
                                </ng-select>
                            </div>
                        </div>
                        -->
                   

                        <div class="col-12">
                            <h5><u>Filtros adicionales</u></h5>
                   
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <div class="form-group">
                                        <div class="col-form-label">Por texto</div>
                                        <input class="form-control" type="text"
                                            [(ngModel)]="filtros.texto" placeholder="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 div-button_filter">
                            <button id="make_filter" type="button" class="btn btn-primary"
                                (click)="rerender()" [attr.aria-expanded]="!isCollapsed"
                                aria-controls="collapseExample"
                                [attr.disabled]="buttonFiltrarTexto != 'Filtrar' ? true:null">
                                <span>{{buttonFiltrarTexto}}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <div class="text-first">
                        <small>Aca se muestra todo el listado de campañas generados por el sistema.</small>
                    </div>
                    <div class="media-body text-end">
                        <a [routerLink]="'add'" class="btn btn-primary me-2">Crear campaña +</a>
                        <a (click)="load_campaigns()" role="button" class="btn btn-secondary">Actualizar</a>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table table-concesionarios">
                        <thead>
                            <tr>
                                <th class="text-center" scope="col">Nombre de la campaña</th>
                                <th class="text-center" scope="col">Fecha</th>
                                <th class="text-center" scope="col">Tipo de campaña</th>
                                <th class="text-center" scope="col">Audiencia (Personas)</th>
                                <th class="text-center" scope="col">Estado</th>
                                <th class="text-center" scope="col">Lanzamiento Programado</th>
                                <th class="text-center" scope="col">Reporte</th>
                                <th class="text-center" scope="col"  *ngIf="cancel_campaign_view" >Acción</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of campaigns">
                                <td>{{c.name}}</td>
                                <td class="text-center">{{c.created_time}}</td>
                                <td class="text-center">
                                    <span class="badge badge-{{c.type_color}}">{{c.type_name}}</span>
                                </td>
                                <td class="text-center">{{c.cantidadPersonas}}</td>
                                <td class="text-center">

                                    <span *ngIf="c.status != 'Cargando'">
                                        {{c.status}}
                                    </span>
                                    <div class="loader-box" *ngIf="c.status == 'Cargando'">
                                        <div class="loader-7"></div>
                                    </div>
                                </td>
                                <td class="text-center">{{c.fecha_lanzamiento}}</td>

                                <td class="text-center">
                                    <div *ngIf="c.status == 'Enviada'">

                                        <a *ngIf="c.type == 3" target="_blank"
                                            href="https://honda4w.mayucrm.com/Encuesta_Reporte/generar_reporte_campaign_whatsapp/{{c.id}}"><i
                                                class="fa fa-file-excel-o text-green"></i></a>
                                        <a *ngIf="c.type != 3" target="_blank"
                                            href="https://honda4w.mayucrm.com/Audiencia_Reporte/generar_reporte_campaign_whatsapp/{{c.id}}"><i
                                                class="fa fa-file-excel-o text-green"></i>
                                        </a>
                                    </div>

                                    <div *ngIf="c.status == 'Programada' || c.status == 'Cargando' || c.status == 'Creada' ">
                            
        
                                        <div class="help" data-container="body" placement="left"
                                            ngbTooltip="Solo podrás el reporte de la campaña cuando esta sea enviada">
                                            <i class="fa fa-info-circle" aria-hidden="true"></i>

                                        </div>
                                 
                                    </div>

                                </td>


                                <td class="text-center" *ngIf="cancel_campaign_view">
                                    <button type="button" class="btn btn-primary-honda" (click)="desactivarCampaign(c.id, 'Cancelar')" *ngIf="c.status == 'Programada' || c.status == 'Creada' "> 
                                        <span>Cancelar Envío <i class="fa fa-ban" aria-hidden="true"></i>  </span>
                                    </button>
  
                                    <div *ngIf="c.status == 'Enviada' || c.status == 'Cancelada' || c.status == 'Cargando'" class="text-muted">
                                        <i class="fa fa-info-circle" aria-hidden="true" ngbTooltip="No puedes realizar acciones en campañas enviadas o canceladas."></i>
                                    </div>
                                </td>

                            </tr>
                        </tbody>
                    </table>


                </div>
            </div>
        </div>
    </div>
</div>
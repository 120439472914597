<app-breadcrumb [title]="'Historial de encuestas'" [items]="['Campañas', 'Configuración','NPS']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">

                <div class="card-header">
                    <div class="text-first">
                        <small>En este módulo, podrás crear encuestas</small>
                    </div>
                    <div class="media-body text-end">
                        <a [routerLink]="'add'" class="btn btn-primary me-2">Crear encuesta +</a>
                    </div>
                </div>

           
                  
                <div class="card-body">

                    <div class="container m-5">
                        <button type="button" 
                                class="btn" 
                                [class.btn-outline-primary]="mostrar !== 'habiles'" 
                                [class.btn-primary]="mostrar === 'habiles'"
                                style="margin-right: 10px;" 
                                (click)="mostrar_encuestas('habiles')" 
                                [disabled]="mostrar === 'habiles'">Ver Encuestas </button>
                        <button type="button" 
                                class="btn" 
                                [class.btn-outline-danger]="mostrar !== 'no habiles'" 
                                [class.btn-danger]="mostrar === 'no habiles'"
                                (click)="mostrar_encuestas('no habiles')" 
                                [disabled]="mostrar === 'no habiles'">Papelera <i  class="fa fa-trash-o" ></i> </button>
                    </div>
                    
                    
                    
                    <div class="table-responsive">

                        <table class="table table-concesionarios">
                            <thead>
                                <ng-container *ngIf="mostrar == 'no habiles'" >

                                    <tr>
                                        <th class="text-center" scope="col">Nombre de la encuesta</th>
                                        <th class="text-center" scope="col">Fecha de creación</th>
                                        <th class="text-center" scope="col">Creado por</th>
                                        <th class="text-center" scope="col">Acciones</th>
        
                                    </tr>

                                </ng-container>

                                <ng-container *ngIf="mostrar == 'habiles'" >

                                    <tr>
                                        <th class="text-center" scope="col">Nombre de la encuesta</th>
                                        <th class="text-center" scope="col">Fecha de creación</th>
                                        <th class="text-center" scope="col">Creado por</th>
                                        <th class="text-center" scope="col">Encuestas Completadas</th>

                                        <th class="text-center" scope="col">Ver</th>
                                        <th class="text-center" scope="col">Registros</th>
                                        <th class="text-center" scope="col">Acciones</th>
        
                                    </tr>

                                </ng-container>

                            </thead>
                            <tbody>
                                <tr *ngFor="let e of encuestas">
                                    <td class="text-center" > {{e.name_server}}</td>
                                    <td class="text-center">{{e.created_time}}</td>
                                    <td class="text-center">
                                        <span class="badge badge-secondary">{{e.seller}}</span>
                                    </td>

                                    <ng-container *ngIf="mostrar == 'habiles'" >

                                        <td class="text-center">
                                            <span class="badge badge-secondary">{{e.cantidad}}</span>
                                        </td>

                                        <td class="text-center">
                                            <a *ngIf="e.status == '1'" target="_blank" href="https://autos.honda.com.pe/encuesta/{{e.id}}/prueba"> <i
                                                    class="fa fa-arrow-right"></i></a>
                                        </td>
        
                                        <td class="text-center">
        
                                            <ng-container *ngIf="e.status == '1' ">
                                                <a  target="_blank" style="color:green; font-weight: bold;" href="https://honda4w.mayucrm.com/Encuesta_Reporte/generar_reporte_by_encuesta_id/{{e.id}}">
                                                    <i class="fa fa-file-excel-o"></i>
                                                </a>
                                            </ng-container>
                                        
                                        </td>

                                    </ng-container>

                                    <td class="text-center">



                                        <ng-container *ngIf="e.veces_cumplidas == 0 && e.status == '1' ">
                                            <p (click)="openDeleteConfirmationModal(e.id)"  style="color:red; font-weight: bold;" >
                                                <i  class="fa fa-trash-o" ></i> 

                                            </p>
                                            
                                        </ng-container>

                                        <ng-container *ngIf="e.status == '0' ">

                                            <p *ngIf="e.status == '0' " 
                                            style="color: rgb(17, 168, 219); font-weight: bold; cursor: pointer;" 
                                            (click)="openRecuperarConfirmationModal(e.id)">
                                                <i class="fa fa-undo"></i> Recuperar
                                            </p>

                                        </ng-container>

                                        
                                        

                                    


                                    </td>

                                

                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="form-group">
        <h2>Detalles de la llamada:</h2>
    </div>
    <div class="row">
 

        <div class="col-sm-12 col-xl-12">
            <div class="card card-absolute">
                <div class="card-header bg-primary">
                    <h5 class="text-white">Resumen de llamada:</h5>
                </div>
                <div class="card-body">
                    <ul>
                        <li *ngFor="let message of comunicacion.data">{{message}}</li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</div>
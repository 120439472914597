import { AfterViewInit, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { LeadsService } from '../shared/services/leads.service';
import { AudienceService } from '../shared/services/audience.service';
import { Router, ActivatedRoute } from '@angular/router';

import Swal from 'sweetalert2';
import { Console } from 'console';


@Component({
  selector: 'app-loyalty-campaigns-audience-add-from-crm',
  templateUrl: './loyalty-campaigns-audience-add-from-crm.component.html',
  styleUrls: ['./loyalty-campaigns-audience-add-from-crm.component.scss']
})
export class LoyaltyCampaignsAudienceAddFromCrmComponent implements AfterViewInit, OnInit {

  audiencia
  audienciaType


  envio
  envioType

  filtros
  default_values
  user_type_id
  buttonFiltrarTexto
  buttonGenerarAudiencia
  isCollapsed

  leads
  total_filtered

  titulo
  audiencia_type

  modelosComunicacion = []
  posiblesModelos = []

  dealers = []
  posiblesDealers = []

  rangosFecha = [
    {
      name: '2 meses',
      to_save: '2_meses',
      start_date: '',
      finish_date: '',
      editable: true
    },
    {
      name: '1 año',
      to_save: '1_ano',
      start_date: '',
      finish_date: '',
      editable: false
    },
    {
      name: 'Más de 1 año',
      to_save: 'mas_1_ano',
      start_date: '',
      finish_date: '',
      editable: false

    },
  ]

  dtElement: DataTableDirective;
  dtTrigger: Subject<void> = new Subject();

  

  constructor(
    private cdr: ChangeDetectorRef,
    private leadService: LeadsService,
    private audienceService: AudienceService,
    public router: Router,
    private route: ActivatedRoute

  ) {
    this.titulo = ""
    this.audiencia_type = ""
    this.filtros = {}
    this.leads = []
    this.default_values = {}
    this.user_type_id = JSON.parse(localStorage.user).user_type_id

    this.buttonFiltrarTexto = `Filtrar`
    this.buttonGenerarAudiencia = false
    this.total_filtered = 0
    this.audiencia = {
      name: '',
      type: '',
      filtros: {}
    }

    this.envio = {
      name: '',
      type: '',
      filtros: {}
    }
    this.envioType = [
      "Email Marketing", "Whatsapp", "SMS"
    ]
    this.audienciaType = [
      'Remarketing', 'Recompra', 'Remarketing Preferencial', 'Recompra Preferencial', 'Encuesta', 'Referidos', 'Planning', 'Marketing'
    ]
  }

  cantidades_leads = [
    '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'Más de 10 leads'
  ]

  cantidades_vehiculos = [
    '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'
  ]

  async ngAfterViewInit() {
    const res = <any>await this.leadService.lead_service_init_general()
    if (res.success) {
      this.default_values = res.payload

      // Agregar Opción Select All
      this.default_values.plataforma.unshift('Select All');
      console.log(this.default_values)
    }
  }

  ngOnInit() {

    this.route.url.subscribe(segments => {
      const currentPath = segments.join('/');

      if (currentPath.includes('audiencias-whatsapp')) {

        this.titulo = 'Generar audiencia por Whatsapp'
        this.audiencia_type = 'Whatsapp'

      } else if (currentPath.includes('audiencias-sms')) {

        this.titulo = 'Generar audiencia por SMS'
        this.audiencia_type = 'SMS'

      }  else {

        this.titulo = 'Generar audiencia por CRM'
        this.audiencia_type = 'Email'
      }

    })

  }

  rerender(): void {
    this.buttonFiltrarTexto = `Cargando...`
    this.make_filter()
  }

  generateModeloComunicacionForRemarketing() {
    var new_modelo_comunicacion = [];
  
    this.modelosComunicacion.forEach(modeloComu => {
      let modelo_encontrado = "";
      
      let modelo_ingresado = modeloComu.modelo;

      const equivalente = this.default_values.equivalentes_modelos.find(eq => eq.modelo === modeloComu.modelo);
      if (equivalente) {
        modelo_ingresado = equivalente.modelo_sistema;
      }

      const existeModelo = this.posiblesModelos.some(posible => posible.name === modelo_ingresado);

      if (existeModelo) {
         modelo_encontrado = modelo_ingresado;
      } 

      new_modelo_comunicacion.push({ ...modeloComu, 'modelo_comunicar': modelo_encontrado });

    });
    
    this.modelosComunicacion = new_modelo_comunicacion

  }

  
  generateDealerComunicacion() {
    const dealers = [];
  
    this.dealers.forEach(dealer => {
      console.log(dealer);
      console.log(this.posiblesDealers);

      var dealerId = "";
      const equivalente = this.default_values.equivalentes_tiendas.find(eq => eq.tienda == dealer.dealer_nombre);

      if (equivalente) {
        dealerId = equivalente.concesionario_id;
      }
      
      dealers.push({ ...dealer, dealer_elegido: dealerId });
    });
    
    this.dealers = dealers;

    console.log(this.dealers);
}


  async make_filter() {
    this.filtros.start = 1
    this.filtros.length = 1
    this.filtros.type = this.audiencia_type;

    Swal.showLoading()

    let res;


    if(this.filtros.politica){
      this.filtros.politica = ["on", "si", "true"];
    } 

    if(this.filtros.datos){
      this.filtros.datos = ["on", "si", "true"];
    } 

    res = <any>await this.leadService.make_filter_for_audience(this.filtros, this.audiencia.type )


    Swal.close()
    if (res.success) {
      this.leads = res.data
      this.total_filtered = 0
      this.audiencia.filtros = this.filtros


      if (["Recompra", "Recompra Preferencial", "Remarketing", "Remarketing Preferencial", "Planning", "Marketing", "Referidos"].includes(this.audiencia.type)) {
        this.modelosComunicacion = res.modelos
        this.posiblesModelos = res.posiblesModelos

        this.dealers = res.dealers;
        this.posiblesDealers = res.posibles_dealers;
     

        this.generateModeloComunicacionForRemarketing()
        this.generateDealerComunicacion();
      }

      console.log(res.detallado_leads)
      res.detallado_leads.forEach(element => {
        this.total_filtered += parseInt(element.cantidad)
      });

    }

    this.buttonFiltrarTexto = `Filtrar`
    console.log(res)
  }

  async generar_audiencia() {
    const res = await this.validation()
  }

  nuevoTiempo: number | null = null;
  nuevoTiempoGrupoCompra: number | null = null;

  mesesSeleccionados: number[] = []; // Array de números
  grupoComprasSeleccionados: number[] = []; // Array de números

  agregarTiempo() {
    if (this.nuevoTiempo !== null) {
      console.log(this.nuevoTiempo);
      if (!this.mesesSeleccionados.includes(this.nuevoTiempo)) {
        this.mesesSeleccionados = [...this.mesesSeleccionados, this.nuevoTiempo];
      }
      console.log(this.mesesSeleccionados);
  
      this.nuevoTiempo = null;
      this.filtros.tiempoPrimerLead = this.mesesSeleccionados;
    }
  }

  agregarTiempoGrupoCompra() {
    if (this.nuevoTiempoGrupoCompra !== null) {
      if (!this.grupoComprasSeleccionados.includes(this.nuevoTiempoGrupoCompra)) {
        this.grupoComprasSeleccionados = [...this.grupoComprasSeleccionados, this.nuevoTiempoGrupoCompra];
      }
  
      this.nuevoTiempoGrupoCompra = null;
      this.filtros.grupo_compra = this.grupoComprasSeleccionados;
    }
  }


  actualizarSeleccion(selecciones: number[]): void {
    this.mesesSeleccionados = selecciones;
    this.filtros.tiempoPrimerLead = this.mesesSeleccionados;

  }

  actualizarSeleccionVenta(selecciones: number[]): void {
    this.grupoComprasSeleccionados = selecciones;
    this.filtros.grupo_compra = this.grupoComprasSeleccionados;
  }
  

  async validation() {
    Swal.showLoading()

    if (this.audiencia.type == "Remarketing" || this.audiencia.type == "Remarketing Preferencial") {
      if (!this.rangosFecha[0].finish_date) {
        Swal.fire('Debes elegir el criterio de rango de fechas', '', 'error')
        return null;
      }
    }


    Swal.fire({
      title: "¿Estás seguro?",
      html: `Vas a crear una audiencia de <b>${this.total_filtered}</b> persona(s)`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Crear",
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        this.buttonGenerarAudiencia = true
        this.generateAudience()
      }
    });

  }

  async generateAudience() {

    if (this.audiencia.type == "Remarketing" || this.audiencia.type == "Remarketing Preferencial" ) {
      this.audiencia.rangos = this.rangosFecha
      this.audiencia.modelosComunicacion = this.modelosComunicacion
    } else if (this.audiencia.type == "Planning") {
      this.audiencia.modelosComunicacion = this.modelosComunicacion
    } else if (this.audiencia.type == "Marketing") {
      this.audiencia.modelosComunicacion = this.modelosComunicacion
    } else if (this.audiencia.type == "Referidos") {
      this.audiencia.modelosComunicacion = this.modelosComunicacion
    } else if (this.audiencia.type == "Recompra" || "Recompra Preferencial") {
      this.audiencia.modelosComunicacion = this.modelosComunicacion
      this.audiencia.dealers = this.dealers;

      
    }


    let res: any; // Declarar res fuera de los bloques if

    if (this.audiencia_type == 'Whatsapp') {
       res = <any>await this.audienceService.generateAudienceWhatsapp(this.audiencia)
    } else if (this.audiencia_type == 'SMS') {
      res = <any>await this.audienceService.generateAudienceSMS(this.audiencia)
   } else {
      res = <any>await this.audienceService.generateAudience(this.audiencia)
    }  


    if (res && res.success) {
      Swal.fire({
        title: res.message,
        text: ``,
        icon: "success"
      })
      this.buttonGenerarAudiencia = false

      if(this.audiencia_type == 'Email'){
        this.router.navigate(['loyalty-comunication/audiencias']);

      } 
      
      if(this.audiencia_type == 'Whatsapp'){
        this.router.navigate(['loyalty-comunication/audiencias-whatsapp']);
      }

      if(this.audiencia_type == 'SMS'){
        this.router.navigate(['loyalty-comunication/audiencias-sms']);
      } 

    } else {
      Swal.fire({
        title: res.message,
        text: ``,
        icon: "error"
      });
    }


  }

  onEndDateChange(index: number) {
    const endDate = new Date(this.rangosFecha[index].finish_date);
    if (!isNaN(endDate.getTime())) {
      if (index === 0) { // Para "2 meses"
        const startDate = new Date(endDate);
        startDate.setMonth(endDate.getMonth() - 2);
        this.rangosFecha[index].start_date = startDate.toISOString().split('T')[0];

        // Ajustar el rango de "1 año" automáticamente
        const oneYearEndDate = new Date(startDate);
        oneYearEndDate.setDate(startDate.getDate() - 1); // Un día antes del inicio del rango de "2 meses"
        this.rangosFecha[1].finish_date = oneYearEndDate.toISOString().split('T')[0];

        const oneYearStartDate = new Date(oneYearEndDate);
        oneYearStartDate.setMonth(oneYearEndDate.getMonth() - 12); // Restar 12 meses para obtener el inicio
        this.rangosFecha[1].start_date = oneYearStartDate.toISOString().split('T')[0];

        // Ajustar "Más de 1 año" automáticamente
        const moreThanOneYearEndDate = new Date(oneYearStartDate);
        moreThanOneYearEndDate.setDate(oneYearStartDate.getDate() - 1); // Un día antes del inicio del rango de "1 año"
        this.rangosFecha[2].start_date = ""; // Sin inicio definido para "Más de 1 año", o podrías poner un límite muy atrás
        this.rangosFecha[2].finish_date = moreThanOneYearEndDate.toISOString().split('T')[0];
      }
    }
  }

  esEditable(index: number, tipo: string): boolean {
    // Hacer que solo el campo de fecha de fin del primer elemento (2 meses) sea editable
    if (tipo === 'fin') {
      return index === 0; // Solo el primer ítem tiene el campo de fecha de fin editable
    }
    return true; // Por default, todos los campos de fecha de inicio son editables
  }


  /* Cambio */ 

  isItemSelected(item: any): boolean {
    if (item !== 'Select All') {
      if (this.filtros.plataforma) {
        return this.filtros.plataforma.includes(item);
      } else {
        return false;
      }
    }
  }
  
  toggleSelectPlataforma(item: any, isSelected: boolean): void {
    console.log(item);
    if (item === 'Select All') {
      if (isSelected) {
        this.filtros.plataforma = [...this.default_values.plataforma.filter(platform => platform !== 'Select All')];
      } else {
        this.filtros.plataforma = [];
      }
    } else {
      if (isSelected) {
        this.filtros.plataforma = [...new Set([...this.filtros.plataforma, item])];
      } else {
        this.filtros.plataforma = this.filtros.plataforma.filter(platformId => platformId !== item);
      }
    }

    console.log(this.filtros.plataforma);
  }
  

  
}

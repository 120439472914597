<div class="container">
    <div class="picker d-flex justify-content-center align-items-center">
        <h3>Fecha: {{fecha}}</h3>
    </div>
    <div class="picker d-flex justify-content-center align-items-center">
        <ngb-timepicker [(ngModel)]="time" [meridian]="meridian"></ngb-timepicker>
    </div>
    <div class="picker d-block justify-content-center align-items-center ">
        <div class="row">
            <div class="col-12 container-buttons">
                <div class="btn-group" role="group" aria-label="Basic example">

                    <button *ngIf="testride" class="btn btn-outline-primary" type="button"
                        [ngClass]="{'active': activeButton === 'Test-Drive'}" (click)="setActiveButton('Test-Drive')">
                        <div>
                            <i class="description-icon icofont icofont-steering"></i>
                            <span class="description">Test-Drive</span>
                        </div>
                    </button>

                    <button class="btn btn-outline-primary" type="button"
                        [ngClass]="{'active': activeButton === 'Llamada'}" (click)="setActiveButton('Llamada')">
                        <div>
                            <i class="description-icon icofont icofont-mobile-phone"></i>
                            <span class="description">Llamada</span>
                        </div>
                    </button>

                    <button class="btn btn-outline-primary" type="button"
                        [ngClass]="{'active': activeButton === 'Visita'}" (click)="setActiveButton('Visita')">
                        <div>
                            <i class="description-icon icofont icofont-user-suited"></i>
                            <span class="description">Visita</span>
                        </div>
                    </button>

                </div>
            </div>
        </div>
    </div>

    <div class="picker d-flex justify-content-center align-items-center">
        <button (click)="pick_hour()" class="btn-form btn btn-primary">Seleccionar hora</button>
        <button (click)="cancel()" class="btn-form btn btn-warning">Cerrar</button>
    </div>
</div>
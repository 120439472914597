<app-breadcrumb [title]="'Detalle Lead'" [items]="['Registros']" [active_item]="'Leads Modelos Semi Nuevos'"></app-breadcrumb>
<div class="go-back">
    <a (click)="go_back()"><i class="fa fa-arrow-circle-left"></i></a>
</div>
<div class="container-fluid">
    <div class="user-profile">
        <div class="row">
            <!-- user profile first-style start-->
            <div class="col-12 col-sm-12 col-md-12 col-xl-7 col-lg-12">
                <div class="card ribbon-wrapper hovercard text-center">
                    <div class="ribbon ribbon-primary {{lead.bg_class}}">{{lead.estado_cliente}}</div>
                    <div *ngIf="lead.moto_imagen">
                        <div class="cardheader card-imagen"
                            style="background: url('{{lead.moto_imagen}}');">
                        </div>
                    </div>
                    <div class="info">
                        <div class="row">
                            <div class="col-sm-6 col-lg-6 order-sm-1 order-lg-1 order-xl-1">
                                <div class="row">
                                    <div class="col-md-12" >
                                        <div class="ttl-info text-center lead-info">
                                            <h6><i class="fa fa-envelope"></i> Email</h6>
                                            <span title="{{lead.correo_electronico}}"
                                                class="lead-info__text">{{lead.correo_electronico}}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="ttl-info text-center lead-info">
                                            <h6><i class="fa fa-calendar"></i> Fecha</h6>
                                            <span title="{{lead.created_time}}"
                                                class="lead-info__text">{{lead.created_time}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-lg-12 order-sm-0 order-lg-0 order-xl-0">
                                <div class="user-designation">
                                    <div class="title"><a>{{lead.nombres}}</a>
                                    </div>
                                    <div class="desc mt-2"><b>{{lead.modelo_nombre}}</b></div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-lg-6 order-sm-2 order-lg-2 order-xl-2">
                                <div class="row">
                                    <div class="col-md-12" *ngIf="user_type_id != 15"  >
                                        <div class="ttl-info text-center lead-info">
                                            <h6><i class="fa fa-phone"></i> Teléfono</h6><span title="{{lead.celular}}"
                                                class="lead-info__text">{{lead.celular}}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="ttl-info text-center lead-info">
                                            <h6><i class="fa fa-user"></i> DNI</h6><span title="{{lead.dni}}"
                                                class="lead-info__text">{{lead.dni}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                          

                        <div class="acciones_asesor" >

                            <hr>

                        

                             
                              

                            <div class="contactarse ">
                                <div class="row">

                                    <div class="col-12 opciones" >
                                        <span>
                                          <button  (click)="toggleDetails()" class="btn btn-success">
                                            {{ showDetails ? 'Ocultar Detalles' : 'Ver Detalles del Modelo' }}
                                            <i class="icofont icofont-car"></i>
                                        </button>
                                        </span>
                                      </div>
                                    
        
                                      <div class="row mt-2" *ngIf="showDetails">
                                        <div class="row">
                                            <div class="col-12">
                                              <h2 class="text-center">{{ modeloData.nombre_modelo }}</h2>
                                            </div>
                                        </div>

                                        <!-- Primera columna -->
                                        <div class="col-md-12 mt-2">
                                          <div class="row text-start">
                                            <div class="col-4">
                                              <p><strong>Año:</strong> {{ modeloData.year }}</p>
                                              <p><strong>Tipo de Vehículo:</strong> {{ modeloData.tipo_vehiculo }}</p>
                                              <p><strong>Color:</strong> {{ modeloData.color }}</p>
                                            </div>
                                            <div class="col-4">
                                              <p><strong>Kilometraje:</strong> {{ modeloData.km }} km</p>
                                              <p><strong>Combustible:</strong> {{ modeloData.combustible }}</p>
                                              <p><strong>Motor:</strong> {{ modeloData.motor }}</p>
                                            </div>
                                            <div class="col-4">
                                              <p><strong>Transmisión:</strong> {{ modeloData.transmision }}</p>
                                              <p><strong>Tracción:</strong> {{ modeloData.traccion }}</p>
                                              <p><strong>Placa:</strong> {{ modeloData.placa }}</p>
                                              <p><strong>Único dueño:</strong> {{ modeloData.unico_dueno }}</p>
                                            </div>
                                          </div>
                                        </div>

                                        <!-- 

                                        <hr class="my-4" />
                        
                                        <div class="especificaciones">
                                            <h4 class="text-center">Especificaciones</h4>
                                            <div class="row mt-3">
                                
                                            <div class="col-md-12">
                                                <ul class="row" >
                                                    <li class="col-4" *ngFor="let especificacion of modeloData.especificaciones">✅ {{especificacion}}</li>
                                                </ul>
                                            </div>
                                            
                                            </div>
                                        </div>

                                        -->

                                      </div>

                                      
                                  
                                      <hr class="my-4" />
                             
                                        <div class="col-12 opciones" *ngIf="lead.celular && user_type_id == 15"  >
                                          <span>
                                            <button [innerHTML]="texto_button_call" (click)="make_call()" class="btn"
                                              [ngClass]="{'btn-info': !on_call, 'btn-danger': on_call}"></button>
                                          </span>
                                        </div>
                                      


                                    <div class="col-12 opciones">
                                        <span>
                                            <a [routerLink]="'history'" class="btn btn-danger">Historial de comunicación<br><i
                                            class="icofont icofont-history"></i></a>
                                        </span>
                                    </div>


                                </div>
                            </div>
                        </div>
                  

                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-xl-5 col-lg-12 box-col-5">
                <div class="card info-right">
                    <div class="card-header py-4">
                        <h5>Información</h5>
                    </div>
                    <div class="card-body">
                        <div class="theme-form row">
                            <div class="formulario-edit">
                                <div class="form-group col-12" *ngIf="user_type_id != 15">
                                    <label for="">Concesionario:</label>
                                    <input class="form-control" type="text" placeholder="{{lead.nombre_concesionario}}"
                                        disabled>
                                </div>
                                <div class="form-group col-12" *ngIf="user_type_id != 15">
                                    <label for="">Vendedor:</label>
                                    <input class="form-control" type="text" placeholder="{{lead.nombre_vendedor}}"
                                        disabled>
                                </div>

                                <div class="col-12">
                                    <div class="form-group col-12">
                                        <label class="col-form-label">¿Respondió llamada?</label>
                                        <ng-select placeholder="" [items]="payloadForm.respondio_llamada" disabled
                                            class="js-example-basic-multiple"
                                            [(ngModel)]="lead_to_update.respondio_llamada">
                                        </ng-select>
                                    </div>
                                </div>
                     
                                <div class="col-12">
                                    <div class="form-group col-12">
                                        <label class="col-form-label">¿Envió cotización?</label>
                                        <ng-select placeholder="" [items]="payloadForm.envio_cotizacion" [disabled]="user_type_id != 15"
                                            class="js-example-basic-multiple"
                                            [(ngModel)]="lead_to_update.envio_cotizacion">
                                        </ng-select>
                                    </div>
                                </div>
                           
                                <div class="form-group col-12">
                                    <label for="">Estado del cliente</label>
                                    <ng-select [items]="payloadForm.estado_cliente" [disabled]="user_type_id != 15"
                                        [(ngModel)]="lead_to_update.estado_cliente" class="js-example-basic-multiple">
                                    </ng-select>
                                </div>
                                <div class="form-group col-12">
                                    <label for="">Respuesta genérica</label>
                                    <ng-select [items]="payloadForm.respuestas_genericas" [disabled]="user_type_id != 15"
                                        [(ngModel)]="lead_to_update.respuesta_generica" bindValue="id" bindLabel="name"
                                        class="js-example-basic-multiple">
                                    </ng-select>
                                </div>
                                <div class="form-group" *ngIf="user_type_id != 15">
                                    <label for="primera_apertura"><u>Tiempo de primera apertura:</u></label>
                                    <!-- <input id="primera_apertura" type="text" class="form-control text-center"
                                        [(ngModel)]="tiempos.primera_apertura" disabled> -->
                                    <p>{{tiempos.primera_apertura ? tiempos.primera_apertura : '-' }}</p>
                                </div>
                                <div class="form-group" *ngIf="user_type_id != 15">
                                    <label for="primera_atencion"><u>Tiempo de primera atención:</u></label>
                                    <!-- <input id="primera_atencion" type="text" class="form-control text-center"
                                        [(ngModel)]="tiempos.primera_atencion" disabled> -->
                                    <p>{{tiempos.primera_atencion ? tiempos.primera_atencion : '-'}}</p>
                                </div>
                                <div class="form-group" *ngIf="user_type_id != 15">
                                    <label for="ultimo_click"><u>Último click:</u></label>
                                    <!-- <input id="ultimo_click" type="text" class="form-control text-center"
                                        [(ngModel)]="tiempos.ultimo_click" disabled> -->
                                    <p>{{tiempos.ultimo_click ? tiempos.ultimo_click : '-'}}</p>
                                </div>
                                <div class="form-group" *ngIf="user_type_id != 15">
                                    <label for="last_edit"><u>Última edición:</u></label>
                                    <!-- <input id="last_edit" type="text" class="form-control text-center"
                                        [(ngModel)]="tiempos.last_edit" disabled> -->
                                    <p>{{tiempos.last_edit ? tiempos.last_edit : '-'}}</p>
                                </div>

                                <div class="form-group col-12">
                                    <label for="comentarios">Comentario Cliente</label>
                                    <textarea disabled [(ngModel)]="lead_to_update.comentarios"
                                        class="form-control" name="" id="comentarios" cols="30" rows="3"></textarea>
                                </div>

                                <div class="form-group button-options col-12 m-b-15" *ngIf="user_type_id == 2">
                                    <button class="btn btn-primary" (click)="actualizar()">Guardar <i
                                            class="icofont icofont-save"></i></button>
                                   
                                </div>
                            </div>

                            <div class="form-group col-12">
                                <label for="notas">Crear Nueva Nota </label>
                                <textarea class="form-control" name="" id="comentarios" cols="30" rows="5" [(ngModel)]="nueva_nota"></textarea>
                            </div>
                            
                            <div class="form-group row col-12 m-b-15">
                               
                                <div class="col-6">

                                    <button class="form-group btn btn-success col-12" (click)="guardarNota()" [disabled]="!nueva_nota.trim()">
                                        <i class="icofont icofont-comment"></i> Guardar Nota
                                    </button>
                                </div>
                                <div class="col-6">
                                    <button class="form-group btn btn-info col-12" (click)="toggleCollapse()">
                                        <i class="icofont icofont-speech-comments"></i>
                                        {{ isCollapsed ? 'Mostrar Notas' : 'Ocultar Notas' }}
                                    </button>
                                </div>
                            </div>

                            <div class="form-group row col-12 m-b-15" *ngIf="user_type_id == 15" >
                                <div class="col-6">
                                    <button class="form-group btn btn-danger col-12" (click)="actualizar()">
                                      Guardar Cambios
                                    </button>
                                </div>

                                <div class="col-6">
                                    <button class="form-group btn btn-success col-12" (click)="programar_llamada()" *ngIf="encargado_lead == true" >Ver mi
                                        calendario <i class="icofont icofont-ui-calendar"></i>
                                    </button>
                                </div>
                                
                            </div>


          
                            
                            <div id="comentariosCollapse" class="m-b-15" *ngIf="comentarios_lead && comentarios_lead.length > 0">
                                <div class="card card-body" [ngClass]="{'collapse': isCollapsed}" >
                                    <!-- Encabezado con el estado del cliente -->

                                    <div class="mt-2" *ngFor="let comentario of comentarios_lead">
                                        <div class="border-bottom mb-3">
                                            <p class="font-weight-bold mb-1">Estado del Lead: {{comentario.estado_cliente}}</p>
                                        </div>
                            
                                        <!-- Contenido del comentario -->
                                        <div [ngClass]="' border rounded p-3 ribbon-bg-' + formatearEstadoCliente(comentario.estado_cliente)" >
                                            <p class="mb-2" style="font-size: 11px;">{{comentario.accion}}</p>
                                            <p class="mb-2" style="font-size: 14px;">{{comentario.comentario}}</p>
                                            <p class="mb-0 " style="font-size: 10px;">{{comentario.created_time}}</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            

                            <div class="col-12" *ngIf="lead.utms">
                                <h4><u>UTMS:</u></h4>
                                <div class="row container_utms">
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-center">
                                        <h6>UTM SOURCE</h6>

                                        <ng-container *ngIf="lead.utms.utm_source == 'GOOGLE'; then thenGoogle">
                                        </ng-container>
                                        <ng-container *ngIf="lead.utms.utm_source == 'FACEBOOK'; then thenFacebook">
                                        </ng-container>
                                        <ng-container *ngIf="lead.utms.utm_source == 'INSTAGRAM'; then thenInstagram">
                                        </ng-container>

                                        <ng-container
                                            *ngIf="lead.utms.utm_source !== 'INSTAGRAM' && lead.utms.utm_source !== 'GOOGLE' && lead.utms.utm_source !== 'FACEBOOK'; then thenDefault">
                                        </ng-container>


                                        <label for="">
                                            <ng-template #thenGoogle>
                                                <div class="p-b-15 icon_utm">
                                                    <i class="fa fa-google display-3 text-danger opacity-05"
                                                        aria-hidden="true"></i>
                                                </div>
                                            </ng-template>

                                            <ng-template #thenFacebook>
                                                <div class="p-b-15 icon_utm">
                                                    <i class="fa fa-facebook display-3 text-danger opacity-05"
                                                        aria-hidden="true"></i>
                                                </div>
                                            </ng-template>

                                            <ng-template #thenInstagram>
                                                <div class="p-b-15 icon_utm">
                                                    <i class="fa fa-instagram display-3 text-danger opacity-05"
                                                        aria-hidden="true"></i>
                                                </div>
                                            </ng-template>

                                            <ng-template #thenDefault>
                                                <div class="p-b-15 icon_utm">
                                                    <i class="icofont icofont-speech-comments display-3 text-danger opacity-05"
                                                        aria-hidden="true"></i>
                                                </div>
                                            </ng-template>


                                            <span class="badge rounded-pill badge-primary">
                                                {{lead.utms.utm_source | uppercase}} </span>

                                        </label>
                                    </div>

                                    
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-center">
                                        <h6>UTM MEDIUM</h6>
                                        <div class="p-b-15 icon_utm">
                                            <i class="icofont icofont-comment display-3 text-dark opacity-05"></i>
                                        </div>
                                        <label for="">
                                            <span class="badge rounded-pill badge-secondary">
                                                {{lead.utms.utm_medium | uppercase}} </span>
                                        </label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-center">
                                        <h6>UTM CAMPAIGN</h6>
                                        <div class="p-b-15 icon_utm">
                                            <i class="icofont icofont-anchor display-3 text-warning opacity-05"></i>
                                        </div>
                                        <label for=""><span class="badge rounded-pill badge-warning">
                                                {{lead.utms.utm_campaign | uppercase}} </span></label>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-center">
                                        <h6>UTM TERM</h6>
                                        <div class="p-b-15 icon_utm">
                                            <i class="fa fa-align-left display-3 text-success opacity-05"
                                                aria-hidden="true"></i>
                                        </div>
                                        <label for=""><span class="badge rounded-pill badge-success">
                                                {{lead.utms.utm_content | uppercase}} </span></label>
                                    </div>
                                   

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
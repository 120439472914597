<div class="container mt-5 mb-5">
    <div class="form-group col-12">
        <h2>Llamar</h2>
    </div>
    <div class="row mt-4">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body row" >
                    <div class="col-6">
                        <h5 class="card-title">Nombre Cliente</h5>
                        <p class="card-text">{{nombre_lead}}</p>
                    </div>
                    <div class="col-6">
                        <h5 class="card-title">Número de teléfono</h5>
                        <p class="card-text">{{celular_lead}}</p>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body row" >

                  <div class="info-modelo">
                        <div class="row">
                          <div class="col-12">
                            <h2 class="text-center">{{ modeloData.nombre_modelo }}</h2>
                          </div>
                        </div>
                      
                        <div class="row mt-4">
                          <!-- Primera columna -->
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-3">
                                <p><strong>Año modelo:</strong> {{ modeloData.year }}</p>
                                <p><strong>Tipo de Vehículo:</strong> {{ modeloData.tipo_vehiculo }}</p>
                                <p><strong>Color:</strong>  {{ modeloData.color }}</p>
                               
                              </div>
                              <div class="col-3">
                                <p><strong>Kilometraje:</strong> {{ modeloData.km }} km</p>
                                <p><strong>Combustible:</strong> {{ modeloData.combustible }}</p>
                                <p><strong>Motor:</strong> {{ modeloData.motor }}</p>
                                
                              </div>
                              <div class="col-3">
                                <p><strong>Transmisión:</strong> {{ modeloData.transmision }}</p>
                                <p><strong>Tracción:</strong> {{ modeloData.traccion }}</p>
                                <p><strong>Placa:</strong> {{ modeloData.placa }}</p>
                              </div>
                              <div class="col-3">
                                <p><strong>Único dueño:</strong> {{ modeloData.unico_dueno }}</p>
                              </div>
                            </div>
                          </div>
                      
                    
                      
                        <!-- Lista de especificaciones -->

                        <!--
                        <hr class="my-4" />

                        <div class="especificaciones">
                          <h4 class="text-center">Especificaciones</h4>
                          <div class="row mt-3">
                
                            <div class="col-md-12">
                                <ul class="row" >
                                    <li class="col-3" *ngFor="let especificacion of modeloData.especificaciones">✅ {{especificacion}}</li>
                                </ul>
                            </div>
                            
                          </div>
                        </div>

                         -->
                        
                  </div>
         
                    
                
                  </div>
            </div>
        </div>
    </div>


    <div class="row mt-2">
        <div class="col-md-12">

            <div class="form-group">
                <label for="comentario">Resumen de Llamada:</label>
                <textarea class="form-control" id="comentario" rows="3" placeholder="Escribe aquí tu comentario..." [(ngModel)]="resumenLlamada"></textarea>
            </div>

            <div class="col-12 ">
                <button class="m-2 btn btn-primary" [disabled]="resumenLlamada.trim() == ''" (click)="guardarLlamada(1)">Registrar Llamada </button>
                <button class="m-2 btn btn-info" (click)="guardarLlamada(0)">Cliente no contestó </button>
                <button class="m-2 btn btn-success" (click)="programar_llamada()">Agendar Llamada <i class="icofont icofont-ui-calendar"></i></button>
            </div>


        </div>
    </div>
</div>

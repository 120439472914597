import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { lastValueFrom, Subscription } from 'rxjs'
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SemiNuevoService } from '../semi-nuevo.service';
import { DecimalPipe } from '@angular/common';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-modelos-semi-nuevos',
  templateUrl: './modelos-semi-nuevos.component.html',
  styleUrls: ['./modelos-semi-nuevos.component.scss'],
  providers: [DecimalPipe],

})
export class ModelosSemiNuevosComponent implements OnInit {

    private routeSub: Subscription;
  
    protected products = []

    // ARRAY CON FILTROS
    filtros
    buttonFiltrarTexto
    isCollapsed
    default_values


    constructor(
      private router: Router,
      private route: ActivatedRoute,
      private http: HttpClient,
      private SemiNuevoService: SemiNuevoService,
      private decimalPipe: DecimalPipe,

    ) {
      this.filtros = {}
  
  
    
      this.default_values = {}
    }

    formatDecimal(value: number): string {
      return this.decimalPipe.transform(value, '1.2-2')!;
    }
  
    formatNumber(value: number): string {
      return this.decimalPipe.transform(value, '1.0-0')!;
    }
  

    tasaDeCambio: number = 0; // Ejemplo: 1 USD = 3.75 PEN
  
    convertirADolaresASoles(precio): number {
      const conversion = precio * this.tasaDeCambio;
      return parseFloat(conversion.toFixed(2));
    }
  
    detallado_person = {
      correo: 0,
      celular: 0,
      total: 0,
    }

    async load_products() {

      Swal.showLoading()
  
      const res = <any>await this.SemiNuevoService.get_products_filter(this.filtros);
  
      if (res.success) {
        
        this.products = res.products;
  
        this.detallado_person = {
          correo: res.detallado_leads.correo  ? res.detallado_leads.correo  : 0 ,
          celular: res.detallado_leads.celular ? res.detallado_leads.celular  : 0 ,
          total: res.detallado_leads.total ? res.detallado_leads.total  : 0 ,
        }
      }
      Swal.close()
    }
  
    async ngOnInit() {
      this.init_service()
      this.load_products()
      this.load_values()
    }

    jwt: string;
    categorias = [];
    size = [];
    tipo = [];
    tienda_selected;

    opcionesTipo = [];
    opcionesCombustible = [];
    opcionesMotor = [];
    opcionesTraccion = [];
    opcionesTransmision = [];
    dealers = [];

    opcionesHabilitado = [
        { id: 1, name: 'Habilitado' },
        { id: 0, name: 'Deshabilitado' }
    ];

  
    async init_service() {
      this.jwt = JSON.parse(localStorage.user).jwt;

      this.routeSub = this.route.params.subscribe(parametros => {

        if (isNaN(parametros.tienda_id) || parametros.tienda_id <= 0) {
          this.router.navigate(['/tiendas-modelos-semi-nuevos']);
        }

        this.tienda_selected = parametros.tienda_id

      })

      if (this.tienda_selected) {
        this.filtros.tienda_id = this.tienda_selected;
      }


      const res = <any>await this.SemiNuevoService.lead_service_init_general(this.jwt);
      if (res.success) {


        this.opcionesTipo = res.payload.Tipo;
        this.opcionesCombustible = res.payload.Combustible;
        this.opcionesMotor = res.payload.Motor;
        this.opcionesTraccion = res.payload.Traccion;
        this.opcionesTransmision = res.payload.Transmision;
        this.tasaDeCambio = res.payload.tasaDeCambio;
        this.dealers = res.payload.campus;

      }
    }

    rerender(): void {

      this.buttonFiltrarTexto = `Cargando...`
      this.load_products();

      this.buttonFiltrarTexto = "Filtrar"

    }

    async load_values() {

      this.buttonFiltrarTexto = "Filtrar"

    }

    selectedProducts = [];

    eliminar_seleccionados(){
      if(this.selectedProducts.length != 0){

        var message = 'Se eliminarán las ' + this.selectedProducts.length + ' personas completamente';

        if(this.selectedProducts.length == 1){
          message = 'Se eliminará completamente';
        }  

        Swal.fire({
          title: '¿Estás seguro?',
          text: message,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, eliminar',
          cancelButtonText: 'Cancelar'
        }).then(async (result) => {

          if (result.isConfirmed) {

            const response = await this.SemiNuevoService.eliminar_products_seleccionados(this.selectedProducts);
             if (response.success) {

              this.selectedProducts = [];
    
              Swal.fire(
                'Eliminados!',
                'Los Productos han sido eliminadas del Black List',
                'success'
              );
    
              this.load_products();
    
            }

          }


        });

      } else {
        Swal.fire({
          title: "No hay elementos seleccionados",
          text: "Selecciona al menos un producto",
          icon: "warning"
        });
      }
    }

  updateSelection(person) {
    if (person.selected) {
      this.selectedProducts.push(person);
    } else {
      this.selectedProducts = this.selectedProducts.filter(p => p.id !== person.id);
    }
    console.log(this.selectedProducts)

  }

  selectAll(event) {
    const isChecked = event.target.checked;
    this.selectedProducts = isChecked ? [...this.products] : [];
    this.products.forEach(person => person.selected = isChecked);
    console.log(this.selectedProducts)

  }


  validarCorreo(correo: string): boolean {
    const cadena = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (correo) {
      return cadena.test(correo);
    } else {
      return false;
    }
  }

  validarCelular(celular: string): boolean {
    if (celular) {
      if (this.isNumber(celular)) {
        if (celular.length === 9) {
          return true;
        }
      }
    }
    return false;
  }

  private isNumber(n: any): boolean {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }



  CrearProducto() {
    this.router.navigate(['/create-modelo-semi-nuevo/'+this.tienda_selected]); 
  }
  
 

  async openDeleteConfirmationModal(id: string) {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Se eliminará completamente',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {

        const response = await this.SemiNuevoService.eliminar_product(id);

        if (response.success) {

          Swal.fire(
            'Eliminado!',
            'El producto ha sido eliminada.',
            'success'
          );

          this.load_products();

        }



      }
    });
    
  }


  async openEditonfirmationModal(id: string) {
    if (id) {
      const route_link = '/edit-modelo-semi-nuevo/'+this.tienda_selected +'/'+ id;
      this.router.navigate([route_link]); // Redirige a otra ruta
    }


    
  }
  
  async openDetailfirmationModal(id: string) {
    if (id) {
      const route_link = '/details-product/'+ id;
      console.log(route_link);
      this.router.navigate([route_link]); // Redirige a otra ruta
    }


    
  }
  







}



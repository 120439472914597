<app-breadcrumb [title]="''" [items]="['Semi Nuevos']" [active_item]="'Seleccione tienda'"></app-breadcrumb>


<div class="col-12">
  <div class="card">
    <!-- Cabecera de la tarjeta -->
    <div class="card-header text-center">
      <h3 class="mb-3">Seleccione su Tienda</h3>
      <p class="text-muted">Debe seleccionar una tienda de la lista para continuar.</p>
    </div>

    <!-- Contenido principal -->
    <div class="col-12">
      <div class="p-3 table-responsive">
        <table class="table table-bordered table-striped table-hover table-concesionarios">
          <thead class="table-dark">
            <tr>
              <th scope="col" class="text-center">Región</th>
              <th scope="col" class="text-center">Nombre de Tienda</th>
              <th scope="col" class="text-center">Acción</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let tienda of tiendas">
              <td class="text-center text-dark">{{ tienda.region }}</td>
              <td class="text-center text-dark">{{ tienda.name_sn }}</td>
              <td class="text-center text-dark">
                <!-- Botón mejorado -->
                <a 
                  [routerLink]="'/modelos-semi-nuevos/' + tienda.id" 
                  class="btn btn-primary btn-lg text-light">
                  <i class="fa fa-arrow-circle-right me-2  text-light"></i> Ir a la tienda
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>


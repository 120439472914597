import { Input, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription, lastValueFrom, timer } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-modal-llamadas-telefonicas-detail',
  templateUrl: './modal-llamadas-telefonicas-detail.component.html',
  styleUrls: ['./modal-llamadas-telefonicas-detail.component.scss']
})
export class ModalLlamadasTelefonicasDetailComponent implements OnInit {

  @Input() private comunicacion_id
  @Input() private jwt


  comunicacion: any = {
    'attachment' : ''
  }
  

  constructor(
    private http: HttpClient
  ) { }

  async ngOnInit() {
    const data = new FormData()
    data.append('comunicacion_id', this.comunicacion_id)
    data.append('jwt', JSON.parse(localStorage.user).jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}ComunicacionAPI/get_info_call_by_comunicacion_id`, data))
    if(response.success){
      this.comunicacion = response.comunicaciones
      console.log(this.comunicacion)
    }
  }

}

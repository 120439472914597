<app-breadcrumb [title]="'Modelos'" [items]="['Modelos','Crear Modelo']"></app-breadcrumb>

<div class="container">
    <div class="form-group">
        <nav class="breadcrumb-nav">
            <a [routerLink]="'/modelos-semi-nuevos/' + tienda_selected"> ← Volver a Productos</a>
        </nav>
    </div>
</div>



<div class="container">
    <div class="form-group">
        <h2>Crear Nuevo Vehículo</h2>
    </div>
    <div class="row">
        <!-- Nombre del Modelo -->
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="nombreModelo">Nombre del Modelo</label>
                <input type="text" class="form-control" id="nombreModelo" placeholder="Ingrese el nombre del modelo" [(ngModel)]="vehiculo_a_crear.nombre">
            </div>
        </div>


        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="anioFabricacion">Estado de Modelo</label>
                <select class="form-control" id="active" [(ngModel)]="vehiculo_a_crear.active">
                    <option  [value]="1" >Activo</option>
                    <option  [value]="0">Inactivo </option>

                </select>

            </div>
        </div>



        <!-- Precios dólares -->
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="precioDolares">
                    Precio en dólares (${{formatDecimal(vehiculo_a_crear.precio)}} → S/{{ formatDecimal(precioEnSoles)}})
                </label>
                <input type="text" 
                       (input)="convertirADolaresASoles()" 
                       class="form-control" 
                       id="precioDolares" 
                       placeholder="Ingrese el precio" 
                       [(ngModel)]="vehiculo_a_crear.precio" 
                       maxlength="6" 
                       pattern="\d*" 
                       oninput="this.value = this.value.replace(/\D/g, '').slice(0, 6);">
            </div>
        </div>
        
        

        <!-- Año modelo -->
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="anioFabricacion">Año modelo</label>
                <input type="number" class="form-control" id="anioFabricacion" placeholder="Ingrese el año"
                    [(ngModel)]="vehiculo_a_crear.anio" min="1900" max="2024" step="1"
                    pattern="\d*" 
                    oninput="this.value = this.value.replace(/\D/g, '').slice(0, 4);">
            </div>
        </div>


        <!-- Tipo de vehículo -->
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="tipoVehiculo">Tipo de Vehículo</label>
                <select class="form-control" id="tipoVehiculo" [(ngModel)]="vehiculo_a_crear.tipo">
                    <option *ngFor="let tipo of opcionesTipo" [value]="tipo">{{ tipo }}</option>
                </select>
            </div>
        </div>


        <!-- Color -->
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="color">Color</label>
                <input type="text" class="form-control" id="color" placeholder="Ingrese el color" [(ngModel)]="vehiculo_a_crear.color">
            </div>
        </div>

        <!-- KM -->
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="km">Kilometraje</label>
                <input type="number" class="form-control" id="km" placeholder="Ingrese el kilometraje" [(ngModel)]="vehiculo_a_crear.km"
                pattern="\d*" 
                oninput="this.value = this.value.replace(/\D/g, '').slice(0, 6);">
            </div>
        </div>

        <!-- Combustible -->
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="combustible">Combustible</label>
                <select class="form-control" id="combustible" [(ngModel)]="vehiculo_a_crear.combustible">
                    <option value="" disabled selected> Seleccione una opción </option>
                    <option *ngFor="let combustible of opcionesCombustible" [value]="combustible">{{ combustible }}</option>
                </select>
            </div>
        </div>

        <!-- Motor -->
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="motor">Motor</label>
                <select class="form-control" id="motor" [(ngModel)]="vehiculo_a_crear.motor">
                    <option value="" disabled selected> Seleccione una opción </option>
                    <option *ngFor="let motor of opcionesMotor" [value]="motor">{{ motor }}</option>
                </select>
           
            </div>
        </div>

        <!-- Tracción -->
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="traccion">Tracción</label>
                <select class="form-control" id="traccion" [(ngModel)]="vehiculo_a_crear.traccion">
                    <option value="" disabled selected> Seleccione una opción </option>
                    <option *ngFor="let traccion of opcionesTraccion" [value]="traccion">{{ traccion }}</option>
                </select>
            </div>
        </div>

        <!-- Transmisión -->
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="transmision">Transmisión</label>
                <select class="form-control" id="transmision" [(ngModel)]="vehiculo_a_crear.transmision">
                    <option value="" disabled selected> Seleccione una opción </option>
                    <option *ngFor="let transmision of opcionesTransmision" [value]="transmision">{{ transmision }}</option>
                </select>

            </div>
        </div>

        <!-- Placa -->

        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="placa">Placa</label>
                <select class="form-control" id="placa" [(ngModel)]="vehiculo_a_crear.placa">
                    <option [value]="'Par'">Par</option>
                    <option [value]="'Impar'">Impar</option>
                </select>
            </div>
        </div>
        


        <!-- Único dueño -->
        <div class="col-12 col-md-6">
            <div class="form-group">
                <label for="unicoDueno">Único Dueño</label>
                <select class="form-control" id="unicoDueno" [(ngModel)]="vehiculo_a_crear.unicoDueno">
                    <option [value]="true">Sí</option>
                    <option [value]="false">No</option>
                </select>
            </div>
        </div>

        <hr>



        <div class="row">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <div class="form-group">
                            <h5>Miniatura</h5>
                            <label for="imagenProducto">
                                Tamaño requerido: 686 píxeles de ancho x 500 píxeles de alto, con una resolución mínima de 150 DPI.
                            </label>
                        </div>
                        
                        <!-- Image preview or default image -->
                        
                        <input type="file" 
                               class="form-control" 
                               id="imagenProducto" 
                               (change)="onFileSelected($event)" 
                               accept="image/png, image/jpeg, image/jpg, image/gif">
    
                                <div class="mb-3 mt-2"  style="width:150px; padding: 10px;" *ngIf="imagePreview" >
                                    <div class="image-preview position-relative">
                                        <img  [src]="imagePreview" alt="Imagen del Producto" class="img-thumbnail" style="width: 100%;">
            
                                        <br>
        
                                        <button 
                                        type="button"
                                        class="btn-close position-absolute"
                                        (click)="clearImage()"
                                        >
                                            &times;
                                        </button>
                                    </div>
                                </div>
                               
    
                    </div>
                </div>
            </div>
        </div>
    
        <hr>


    <!-- Specs -->

    <!--
    <div class="col-12">
        <div class="form-group">

            <div class="form-group" >
                <h5>Especificaciones</h5>
                <label for="imagenProducto">Ingrese las especificaciones técnicas o detalles relevantes</label>
            </div>

            <button type="button" class="btn btn-primary ml-2" (click)="addSpec()"> Agregar + </button>

      
            <div *ngFor="let spec of vehiculo_a_crear.specs; let i = index" class="mt-2">
                <div class="input-group mb-2">
                <button
                    type="button"
                    class="btn btn-success"
                    (click)="moveSpecUp(i)"
                    [disabled]="i === 0" 
                >
                    ↑
                </button>
            
                <button
                    type="button"
                    class="btn btn-success ms-2" 
                    (click)="moveSpecDown(i)"
                    [disabled]="i === vehiculo_a_crear.specs.length - 1" 
                >
                    ↓
                </button>
            
                <input
                    type="text"
                    class="form-control ms-2" 
                    [(ngModel)]="spec.value"
                    placeholder="Agregue una especificación"
                />
            
                <button
                    type="button"
                    class="btn btn-danger ms-2" 
                    (click)="removeSpec(i)"
                >
                    Eliminar
                </button>
                </div>
            </div>
            
        </div>
    </div>
    <hr>
    -->

    <!-- Legal -->
    <div class="col-12">
        <div class="form-group">
            <div class="form-group" >
                <h5>Información Legal</h5>
            </div>
            <textarea class="form-control" id="legal" rows="3" placeholder="Ingrese información legal" [(ngModel)]="vehiculo_a_crear.legal"></textarea>
        </div>
    </div>

    <hr>


    <!-- Fotos -->
    <div class="col-12">
        <div class="form-group">
            <h3>Galería</h3>
            
        </div>
        
        <div class="col-12 col-md-12">
            <div class="form-group">
                <label for="fotos">Tamaño requerido por imagen: 1280 pixeles base X 1040 pixeles altura, resolución 150 dpi.</label>
                <!-- Input de archivo oculto -->
                <input
                    type="file"
                    class="form-control"
                    id="fotos"
                    multiple
                    (change)="onFotosSelected($event)"
                    accept="image/*"
                    style="display: none;"
                />
            </div>
            
            <div class="col-12 col-md-12 seleccionar" onclick="document.getElementById('fotos').click()">
                <!-- Imagen o área donde el usuario puede hacer clic -->
                <div class="dz-message needsclick">
                    <i class="icon-cloud-up"></i>
                    <h6>Seleccione las imágenes para la galería.</h6>
                    <span class="bold">(Máximo 6)</span>
                </div>
            </div>
        </div>

        <!-- Cantidad de imágenes seleccionadas o mensaje de no seleccionadas -->
        <div class="mt-3">
            <p class="text-muted" *ngIf="vehiculo_a_crear.fotos.length === 0">Sin imágenes seleccionadas</p>
            <p class="text-muted" *ngIf="vehiculo_a_crear.fotos.length > 0">
                <strong>{{ vehiculo_a_crear.fotos.length }}</strong> imágenes seleccionadas.
            </p>
        </div>

        <div class="col-12">
            <div class="row">
                <div
                    class="col-6 col-md-4 mb-3"
                    *ngFor="let image of vehiculo_a_crear.fotos; let i = index"
                >
                    <div class="image-preview position-relative">
                        <!-- Vista previa de la imagen -->
                        <img 
                            [src]="image.url"
                            alt="Preview"
                            class="img-thumbnail preview-img"
                        />
                    
                        <!-- Botón para eliminar -->
                        <button
                            type="button"
                            class="btn-close position-absolute"
                            (click)="removeImage(i)"
                        >
                            &times;
                        </button>
                
                        <!-- Selector para imagen principal -->

                  
                
                        <!-- Botones para mover -->
                        <div class="d-flex justify-content-between mt-2">
                            <button
                                class="btn btn-sm btn-primary"
                                (click)="moveImage(i, 'up')"
                                [disabled]="i === 0"
                            >
                            ←
                            </button>
                            <button
                                class="btn btn-sm btn-primary"
                                (click)="moveImage(i, 'down')"
                                [disabled]="i === vehiculo_a_crear.fotos.length - 1"
                            > 
                            →
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
    </div>

    <!-- Botones -->
    <div class="form-group buttons">
        <button [attr.disabled]="is_loading ? true : null" class="btn btn-primary" (click)="onSubmit()">Agregar Vehículo</button>
        <button class="btn btn-secondary" (click)="onCancel()">Cancelar</button>
    </div>
</div>

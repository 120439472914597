import { Input, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { JsonPipe } from '@angular/common';
import { NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-programar-llamada-hourpicker',
  templateUrl: './modal-programar-llamada-hourpicker.component.html',
  styleUrls: ['./modal-programar-llamada-hourpicker.component.scss']
})
export class ModalProgramarLlamadaHourpickerComponent implements OnInit {

  @Input() fecha
  @Input() testride

  time = { hour: 13, minute: 30 };
  activeButton: string = 'Test-Drive'
  meridian = true;
  testride_boolean = true;

  toggleMeridian() {
    this.meridian = !this.meridian;
  }

  setActiveButton(button: string): void {
    this.activeButton = button;
    console.log(this.activeButton)
  }


  constructor(
    private activeModalService: NgbActiveModal
  ) { }

  ngOnInit(): void {

    if (this.testride) {
      this.testride_boolean = false;
    }
    this.setActiveButton('Llamada');
  }


  complementarNumero(numero) {
    return numero.toString().padStart(2, '0');
  }



  pick_hour() {

    if (this.time === null) {
      this.time = {
        hour: 0,
        minute: 0
      }
    }
    this.time.hour = this.complementarNumero(this.time.hour)
    this.time.minute = this.complementarNumero(this.time.minute)



    this.activeModalService.close({
      success: true,
      data: {
        fecha: this.fecha + ` ${this.time.hour}:${this.time.minute}:00`,
        motivo: this.activeButton
      }
    })
  }

  cancel() {
    this.activeModalService.close({
      success: false
    })

  }

}

import { Routes } from '@angular/router';
import { LeadsComponent } from 'src/app/components/leads/leads.component';
import { SelectTiendaComponent } from 'src/app/components/select-tienda/select-tienda.component';
import { LeadDetalleComponent } from 'src/app/components/lead-detalle/lead-detalle.component';
import { ReportesComponent } from 'src/app/reportes/reportes.component';
import { AsesorSelectTiendaComponent } from 'src/app/asesor-select-tienda/asesor-select-tienda.component';
import { RegistrarPedidoComponent } from 'src/app/components/registrar-pedido/registrar-pedido.component'
import { ColaDeRequerimientoComponent } from 'src/app/components/cola-de-requerimiento/cola-de-requerimiento.component';
import { RegistrosComponent } from 'src/app/components/registros/registros.component';
import { LeadWhatsappComponent } from 'src/app/components/lead-whatsapp/lead-whatsapp.component';
import { LeadMailComponent } from 'src/app/components/lead-mail/lead-mail.component';
import { UsersComponent } from 'src/app/components/users/users.component';
import { UsersDescansoComponent } from 'src/app/components/users-descanso/users-descanso.component';
import { UsersActividadComponent } from 'src/app/components/users-actividad/users-actividad.component';
import { ConcesionariosComponent } from 'src/app/components/concesionarios/concesionarios.component';
import { DashboardSesionComponent } from 'src/app/components/dashboard-sesion/dashboard-sesion.component';
import { CreateLeadComponent } from 'src/app/create-lead/create-lead.component';
import { JefeSelectTiendaComponent } from 'src/app/jefe-select-tienda/jefe-select-tienda.component';
import { HistoryComunicationComponent } from 'src/app/history-comunication/history-comunication.component';
import { NotificacionesComponent } from 'src/app/notificaciones/notificaciones.component';
import { LoyaltyCampaignsComponent } from 'src/app/loyalty-campaigns/loyalty-campaigns.component';
import { LoyaltyCampaignsWhatsappComponent } from 'src/app/loyalty-campaigns-whatsapp/loyalty-campaigns-whatsapp.component';
import { LoyaltyCampaignsAddComponent } from 'src/app/loyalty-campaigns-add/loyalty-campaigns-add.component';
import { LoyaltyCampaignsDetailComponent } from 'src/app/loyalty-campaigns-detail/loyalty-campaigns-detail.component';
import { LoyaltyCampaignsConfigComponent } from 'src/app/loyalty-campaigns-config/loyalty-campaigns-config.component';
import { LoyaltyCampaignsConfigReportesComponent } from 'src/app/loyalty-campaigns-config-reportes/loyalty-campaigns-config-reportes.component';
import { LoyaltyCampaignsConfigPlantillasComponent } from 'src/app/loyalty-campaigns-config-plantillas/loyalty-campaigns-config-plantillas.component';
import { LoyaltyCampaignsConfigCampaigntypeComponent } from 'src/app/loyalty-campaigns-config-campaigntype/loyalty-campaigns-config-campaigntype.component';
import { LoyaltyCampaignsAudienceComponent } from 'src/app/loyalty-campaigns-audience/loyalty-campaigns-audience.component';
import { LoyaltyCampaignsAudienceAddFromCrmComponent } from 'src/app/loyalty-campaigns-audience-add-from-crm/loyalty-campaigns-audience-add-from-crm.component';
import { LoyaltyCampaignsAudienceAddFromExcelComponent } from 'src/app/loyalty-campaigns-audience-add-from-excel/loyalty-campaigns-audience-add-from-excel.component';
import { NpsComponent } from 'src/app/nps/nps.component';
import { NpsAddComponent } from 'src/app/nps-add/nps-add.component';
import { ReportesAntiguosComponent } from 'src/app/reportes-antiguos/reportes-antiguos.component';
import { TemplatesPickerComponent } from 'src/app/templates-picker/templates-picker.component';
import { TemplatesMetaComponent } from 'src/app/templates-meta/templates-meta.component';
import { CampaignTypePickerComponent } from 'src/app/campaign-type-picker/campaign-type-picker.component';
import { LoyaltyCampaignsWhatsappAddComponent } from 'src/app/loyalty-campaigns-whatsapp-add/loyalty-campaigns-whatsapp-add.component';
import { AudienceTypePickerComponent } from 'src/app/audience-type-picker/audience-type-picker.component';
import { LoyaltyCampaignsTemplatesComponent } from 'src/app/loyalty-campaigns-templates/loyalty-campaigns-templates.component';
import { LoyaltyCampaignsConfigPlantillasWhatsappComponent } from 'src/app/loyalty-campaigns-config-plantillas-whatsapp/loyalty-campaigns-config-plantillas-whatsapp.component';
import { LoyaltyBlackListComponent } from 'src/app/loyalty-black-list/loyalty-black-list.component';
import { ImportBlackListComponent } from 'src/app/import-black-list/import-black-list.component';


import { LoyaltyCampaignsSmsComponent } from 'src/app/loyalty-campaigns-sms/loyalty-campaigns-sms.component';
import { LoyaltyCampaignsSmsAddComponent } from 'src/app/loyalty-campaigns-sms-add/loyalty-campaigns-sms-add.component';
import { ReporteConsumoComponent } from 'src/app/reporte-consumo/reporte-consumo.component';
import { ModelosSemiNuevosComponent } from 'src/app/modelos-semi-nuevos/modelos-semi-nuevos.component';
import { CreateModeloSemiNuevoComponent } from 'src/app/create-modelo-semi-nuevo/create-modelo-semi-nuevo.component';
import { EditModeloSemiNuevoComponent } from 'src/app/edit-modelo-semi-nuevo/edit-modelo-semi-nuevo.component';
import { TiendasModelosSemiNuevosComponent } from 'src/app/tiendas-modelos-semi-nuevos/tiendas-modelos-semi-nuevos.component';
import { LeadsSemiNuevosComponent } from 'src/app/leads-semi-nuevos/leads-semi-nuevos.component';
import { SelectTiendaLeadsSemiNuevosComponent } from 'src/app/select-tienda-leads-semi-nuevos/select-tienda-leads-semi-nuevos.component';
import { LeadsDetalleSemiNuevosComponentComponent } from 'src/app/leads-detalle-semi-nuevos-component/leads-detalle-semi-nuevos-component.component';
import { HistoryComunicationSemiNuevosComponent } from 'src/app/history-comunication-semi-nuevos/history-comunication-semi-nuevos.component';

export const content: Routes = [
  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule)
  // },

  // MODELOS SEMI NUEVOS
  {
    path: 'tiendas-modelos-semi-nuevos',
    component: TiendasModelosSemiNuevosComponent,
  },
  {
    path: 'modelos-semi-nuevos/:tienda_id',
    component: ModelosSemiNuevosComponent,
  },
  {
    path: 'create-modelo-semi-nuevo/:tienda_id',
    component: CreateModeloSemiNuevoComponent,
  },
  {
    path: 'edit-modelo-semi-nuevo/:tienda_id/:id',
    component: EditModeloSemiNuevoComponent,
  },
  {
    path: 'leads-modelo-semi-nuevo',
    component: LeadsSemiNuevosComponent,
  },
  {
    path: 'leads-modelo-semi-nuevo/:tienda_id',
    component: SelectTiendaLeadsSemiNuevosComponent
  },
  {
    path: 'leads-modelo-semi-nuevo/:tienda_id/:lead_id',
    component: LeadsDetalleSemiNuevosComponentComponent
  },
  {
    path: 'leads-modelo-semi-nuevo/:tienda_id/:lead_id/history',
    component: HistoryComunicationSemiNuevosComponent
  },

  // FIN DE MODELOS SEMINUEVOS
  {
    path: '',
    component: ReportesComponent,
  },
  {
    path: 'reporte_consumo',
    component: ReporteConsumoComponent,
  },
  {
    path: 'dashboard',
    component: ReportesComponent,
  },
  {
    path: 'dashboard/sesiones',
    component: DashboardSesionComponent,
  },
  {
    path: 'dashboard/:rol',
    component: ReportesComponent,
  },

  {
    path: 'leads',
    component: LeadsComponent,
  },
  {
    path: 'leads/tienda',
    component: SelectTiendaComponent
  },
  {
    path: 'leads/tienda/:tienda_id',
    component: SelectTiendaComponent
  },
  {
    path: 'leads/tienda/:tienda_id/:lead_id',
    component: LeadDetalleComponent
  },
  {
    path: 'leads/tienda/:tienda_id/:lead_id/whatsapp',
    component: LeadWhatsappComponent
  },
  {
    path: 'leads/tienda/:tienda_id/:lead_id/history',
    component: HistoryComunicationComponent
  },
  {
    path: 'leads/tienda/:tienda_id/:lead_id/mail',
    component: LeadMailComponent
  },
  {
    path: 'asesor-select-tienda',
    component: AsesorSelectTiendaComponent
  },
  {
    path: 'jefe-select-tienda',
    component: JefeSelectTiendaComponent
  },
  {
    path: 'registrar-pedido',
    component: RegistrarPedidoComponent
  },
  {
    path: 'cola-de-requerimiento',
    component: ColaDeRequerimientoComponent
  },
  {
    path: 'registros/:nombre',
    component: RegistrosComponent
  },
  {
    path: 'users',
    component: UsersComponent
  },
  {
    path: 'users/:usuario_id',
    component: UsersComponent
  },
  {
    path: 'users/:usuario_id/actividad',
    component: UsersActividadComponent
  },

  {
    path: 'users/:usuario_id/descanso',
    component: UsersDescansoComponent
  },
  {
    path: 'concesionarios',
    component: ConcesionariosComponent
  },
  {
    path: 'concesionarios/:concesionario_id',
    component: ConcesionariosComponent
  },
  {
    path: 'create-lead',
    component: CreateLeadComponent
  },
  {
    path: 'notificaciones',
    component: NotificacionesComponent
  },
  {
    path: 'loyalty-comunication/campaigns',
    component: LoyaltyCampaignsComponent
  },
  {
    path: 'loyalty-comunication/campaigns/add',
    component: LoyaltyCampaignsAddComponent
  },
  {
    path: 'loyalty-comunication/campaigns/detail/:campaign_id',
    component: LoyaltyCampaignsDetailComponent
  },
  {
    path: 'loyalty-comunication/campaigns/config',
    component: LoyaltyCampaignsConfigComponent
  },
  {
    path: 'loyalty-comunication/campaigns/reportes-antiguos',
    component: ReportesAntiguosComponent
  },
  {
    path: 'loyalty-comunication/type-audiencia',
    component: AudienceTypePickerComponent
  },
  {
    path: 'loyalty-comunication/audiencias',
    component: LoyaltyCampaignsAudienceComponent
  },
  {
    path: 'loyalty-comunication/audiencias-sms',
    component: LoyaltyCampaignsAudienceComponent
  },
  {
    path: 'loyalty-comunication/audiencias-whatsapp',
    component: LoyaltyCampaignsAudienceComponent
  },
  {
    path: 'loyalty-comunication/black-list/add-from-excel',
    component: ImportBlackListComponent,
  },
  {
    path: 'loyalty-comunication/black-list',
    component: LoyaltyBlackListComponent,
  },
  {
    path: 'loyalty-comunication/audiencias-sms/add-from-crm',
    component: LoyaltyCampaignsAudienceAddFromCrmComponent
  },

  {
    path: 'loyalty-comunication/audiencias-sms/add-from-excel',
    component: LoyaltyCampaignsAudienceAddFromExcelComponent
  },
  {
    path: 'loyalty-comunication/audiencias-whatsapp/add-from-crm',
    component: LoyaltyCampaignsAudienceAddFromCrmComponent
  },

  {
    path: 'loyalty-comunication/audiencias-whatsapp/add-from-excel',
    component: LoyaltyCampaignsAudienceAddFromExcelComponent
  },

  {
    path: 'loyalty-comunication/audiencias/add-from-crm',
    component: LoyaltyCampaignsAudienceAddFromCrmComponent
  },

  {
    path: 'loyalty-comunication/audiencias/add-from-excel',
    component: LoyaltyCampaignsAudienceAddFromExcelComponent
  },
  {
    path: 'loyalty-comunication/campaigns/from-email/add',
    component: LoyaltyCampaignsAddComponent
  },
  {
    path: 'loyalty-comunication/campaigns/from-email',
    component: LoyaltyCampaignsComponent
  },

  {
    path: 'loyalty-comunication/campaigns/from-whatsapp/add',
    component: LoyaltyCampaignsWhatsappAddComponent
  },
  {
    path: 'loyalty-comunication/campaigns/from-whatsapp',
    component: LoyaltyCampaignsWhatsappComponent
  },
  {
    path: 'loyalty-comunication/campaigns/from-SMS/add',
    component: LoyaltyCampaignsSmsAddComponent
  },
  {
    path: 'loyalty-comunication/campaigns/from-SMS',
    component: LoyaltyCampaignsSmsComponent
  },
  {
    path: 'loyalty-comunication/campaigns/config/reportes',
    component: LoyaltyCampaignsConfigReportesComponent
  },

  {
    path: 'loyalty-comunication/campaigns/type-templates',
    component: LoyaltyCampaignsTemplatesComponent
  },

  {
    path: 'loyalty-comunication/campaigns/config/templates',
    component: LoyaltyCampaignsConfigPlantillasComponent
  },
  {
    path: 'loyalty-comunication/campaigns/config/templates-whatsapp',
    component: LoyaltyCampaignsConfigPlantillasWhatsappComponent
  },

  {
    path: 'loyalty-comunication/campaigns/config/templates-picker',
    component: TemplatesPickerComponent
  },
  {
    path: 'loyalty-comunication/campaigns/campaign-type-picker',
    component: CampaignTypePickerComponent
  },
  {
    path: 'loyalty-comunication/campaigns/config/templates-meta',
    component: TemplatesMetaComponent
  },
  {
    path: 'loyalty-comunication/campaigns/config/campaign-type',
    component: LoyaltyCampaignsConfigCampaigntypeComponent
  },
  {
    path: 'loyalty-comunication/nps',
    component: NpsComponent
  },
  {
    path: 'loyalty-comunication/nps/add',
    component: NpsAddComponent
  },
  {
    path: 'widgets',
    loadChildren: () => import('../../components/widgets/widgets.module').then(m => m.WidgetsModule)
  },
  {
    path: 'ui-kits',
    loadChildren: () => import('../../components/ui-kits/ui-kits.module').then(m => m.UiKitsModule)
  },
  {
    path: 'base',
    loadChildren: () => import('../../components/bonus-ui/base/base.module').then(m => m.BaseModule)
  },
  {
    path: 'advance',
    loadChildren: () => import('../../components/bonus-ui/advance/advance.module').then(m => m.AdvanceModule)
  },
  {
    path: 'project',
    loadChildren: () => import('../../components/apps/project/project.module').then(m => m.ProjectModule)
  },
  {
    path: 'ecommerce',
    loadChildren: () => import('../../components/apps/e-commerce/e-commerce.module').then(m => m.ECommerceModule)
  },
  {
    path: 'email',
    loadChildren: () => import('../../components/apps/email/email.module').then(m => m.EmailModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('../../components/apps/chat/chat.module').then(m => m.ChatModule)
  },
  {
    path: 'user',
    loadChildren: () => import('../../components/apps/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'bookmarks',
    loadChildren: () => import('../../components/apps/bookmarks/bookmarks.module').then(m => m.BookmarksModule)
  },
  {
    path: 'file-manager',
    loadChildren: () => import('../../components/apps/file-manager/file-manager.module').then(m => m.FileManagerModule)
  },
  {
    path: 'contacts',
    loadChildren: () => import('../../components/apps/contacts/contacts.module').then(m => m.ContactsModule)
  },
  {
    path: 'tasks',
    loadChildren: () => import('../../components/apps/tasks/tasks.module').then(m => m.TasksModule)
  },
  {
    path: 'calender',
    loadChildren: () => import('../../components/apps/calender/calender.module').then(m => m.CalenderModule)
  },
  {
    path: 'social-app',
    loadChildren: () => import('../../components/apps/social-app/social-app.module').then(m => m.SocialAppModule)
  },
  {
    path: 'to-do',
    loadChildren: () => import('../../components/apps/todo/todo.module').then(m => m.TodoModule),
  },
  {
    path: 'buttons',
    loadChildren: () => import('../../components/buttons/buttons.module').then(m => m.ButtonsModule)
  },
  {
    path: 'to-do-firebase',
    loadChildren: () => import('../../components/apps/to-do-firebase/to-do-firebase.module').then(m => m.ToDoFirebaseModule)
  },
  {
    path: 'editor',
    loadChildren: () => import('../../components/editors/editor.module').then(m => m.EditorModule)
  },
  {
    path: 'chart',
    loadChildren: () => import('../../components/charts/charts.module').then(m => m.ChartModule),
  },
  {
    path: 'icons',
    loadChildren: () => import('../../components/icons/icons.module').then(m => m.IconsModule)
  },
  {
    path: 'form',
    loadChildren: () => import('../../components/forms/forms.module').then(m => m.FormModule)
  },
  {
    path: 'table',
    loadChildren: () => import('../../components/table/table.module').then(m => m.TableModule)
  },
  {
    path: 'cards',
    loadChildren: () => import('../../components/cards/cards.module').then(m => m.CardsModule)
  },
  {
    path: 'sample-page',
    loadChildren: () => import('../../components/others/sample/sample.module').then(m => m.SampleModule)
  },
  {
    path: 'gallery',
    loadChildren: () => import('../../components/apps/gallery/gallery.module').then(m => m.GalleryDemoModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('../../components/apps/blog/blog.module').then(m => m.BlogModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('../../components/apps/faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: 'job',
    loadChildren: () => import('../../components/apps/job-search/job-search.module').then(m => m.JobSearchModule)
  },
  {
    path: 'learning',
    loadChildren: () => import('../../components/apps/learning/learning.module').then(m => m.LearningModule)
  },
  {
    path: 'knowledgebase',
    loadChildren: () => import('../../components/apps/knowledge-base/knowledge-base.module').then(m => m.KnowledgeBaseModule)
  },
  {
    path: 'map',
    loadChildren: () => import('../../components/apps/map/map.module').then(m => m.MapModule)
  },
  {
    path: 'support-ticket',
    loadChildren: () => import('../../components/apps/support-ticket/support-ticket.module').then(m => m.SupportTicketModule)
  },
  {
    path: 'search-pages',
    loadChildren: () => import('../../components/others/search-result/search-result.module').then(m => m.SearchResultModule)
  },

];

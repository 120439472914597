import { Component, OnInit, Input } from '@angular/core';
import { CampaignService } from '../shared/services/campaign.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-table-detalle-lead',
  templateUrl: './table-detalle-lead.component.html',
  styleUrls: ['./table-detalle-lead.component.scss']
})
export class TableDetalleLeadComponent implements OnInit {

  @Input() tittle: string;
  @Input() tipo: string;

  @Input() datos: any;
  @Input() modelos_data: any;

  @Input() colapsan_value: string;
  
  @Input() date: string;

  reporte_leads: []; 
  reporte_leads_total: []; 
  reporte_modelos= [];

  protected Object = Object;
  modelos: string[] = [];
  tipos: string[] = [];
  CampaignService: any;

  constructor(
    private campaignService: CampaignService,
  ) { }

  getClassForValue(value: number): string {
    if (value < 40) {
        return 'bg-yellow';
    } else if (value >= 40 && value <= 60) {
        return 'bg-orange';
    } else if (value > 60) {
        return 'bg-green';
    }
    return '';
}


  getTotalProyectado(): number {
    return this.reporte_modelos.reduce((total, modelo) => total + modelo.cantidad, 0);
  }

  ngOnInit(): void {
    this.reporte_modelos = this.modelos_data;
    this.reporte_leads = this.datos;

    console.log(this.reporte_modelos);

    if (Object.keys(this.reporte_leads).length > 0) {
      this.extractModelosYTipos();
    }
    

  }

  extractModelosYTipos() {
    const modelosSet = new Set<string>();
    const tiposSet = new Set<string>();

    // Extraer modelos y tipos únicos
    Object.values(this.reporte_leads).forEach((dailyData: any) => {
      Object.keys(dailyData).forEach(modelo => {
        modelosSet.add(modelo);
        Object.keys(dailyData[modelo]).forEach(tipo => {
          tiposSet.add(tipo);
        });
      });
    });

    this.modelos = Array.from(modelosSet);
    this.tipos = Array.from(tiposSet);


  }

  getValue(fecha: string, modelo: string, tipo: string): string | number {
    return this.reporte_leads[fecha]?.[modelo]?.[tipo]?.cantidad || '0';
  }
  getLeads(fecha: string, modelo: string, tipo: string): string | number {
    return this.reporte_leads[fecha]?.[modelo]?.[tipo]?.Leads || '0';
  }

  calcularTotalModelo(modelo: string, tipo: string): number {
    let total = 0;
    
    Object.keys(this.reporte_leads).forEach(fecha => {
      const valorStr = this.reporte_leads[fecha]?.[modelo]?.[tipo]?.cantidad;
      if (valorStr) {
        // Aseguramos que sea un número y sumamos
        const valor = parseInt(valorStr, 10);
        if (!isNaN(valor)) {
          total += valor;
        }
      }
    });
    
    return total;
  }

  getTotalLeadsModelo(modelo: string, tipo: string): number {
    let total = 0;
    
    Object.keys(this.reporte_leads).forEach(fecha => {
      const valorStr = this.reporte_leads[fecha]?.[modelo]?.[tipo]?.Leads;
      if (valorStr) {
        // Aseguramos que sea un número y sumamos
        const valor = parseInt(valorStr, 10);
        if (!isNaN(valor)) {
          total += valor;
        }
      }
    });
    
    return total;
  }

  getTotalLeadsByDate(fecha: string): number {
    let total = 0;

    const fechaData = this.reporte_leads[fecha];
    if (fechaData) {
        Object.keys(fechaData).forEach(modelo => {
            Object.keys(fechaData[modelo]).forEach(tipo => {
                const valorStr = fechaData[modelo][tipo]?.Leads;
                if (valorStr) {
                    // Aseguramos que sea un número y sumamos
                    const valor = parseInt(valorStr, 10);
                    if (!isNaN(valor)) {
                        total += valor;
                    }
                }
            });
        });
    }

    return total;
  }

  getTotalCantidadByDate(fecha: string): number {
      let total = 0;

      const fechaData = this.reporte_leads[fecha];
      if (fechaData) {
          Object.keys(fechaData).forEach(modelo => {
              Object.keys(fechaData[modelo]).forEach(tipo => {
                  const valorStr = fechaData[modelo][tipo]?.cantidad;
                  if (valorStr) {
                      // Aseguramos que sea un número y sumamos
                      const valor = parseInt(valorStr, 10);
                      if (!isNaN(valor)) {
                          total += valor;
                      }
                  }
              });
          });
      }

      return total;
  }



  calcularTotal(): number {
    let total = 0;
  
    Object.keys(this.reporte_leads).forEach(fecha => {
      Object.keys(this.reporte_leads[fecha]).forEach(modelo => {
  
        Object.keys(this.reporte_leads[fecha][modelo]).forEach(tipo => {
  
          const valorStr = this.reporte_leads[fecha][modelo]?.[tipo]?.cantidad;
          if (valorStr) {
            const valor = parseInt(valorStr, 10);
            if (!isNaN(valor)) {
              total += valor;
            }
          }
        });
  
      });
    });
  
    return total;
  }

  getTotalLeads(): number {
    let total = 0;
  
    Object.keys(this.reporte_leads).forEach(fecha => {
      Object.keys(this.reporte_leads[fecha]).forEach(modelo => {
        Object.keys(this.reporte_leads[fecha][modelo]).forEach(tipo => {
          const valorStr = this.reporte_leads[fecha][modelo]?.[tipo]?.Leads;
          const valor = Number(valorStr); // Convierte el valor a número
          if (!isNaN(valor)) {
            total += valor; // Suma solo si es un número válido
          }
        });
      });
    });
  
    return total;
  }
  
  
  actualizarCantidad(nuevoValor: number, modelo: any) {
      modelo.cantidad = nuevoValor;
      
      if (modelo.mensajes_enviados > 0) {
          modelo.conversion = (modelo.leads_obtenidos / modelo.mensajes_enviados) * 100;
      }
      
      if (modelo.cantidad > 0) {
          modelo.logro = (modelo.leads_obtenidos / modelo.cantidad) * 100;
      }

      this.guardarCambios(modelo);
  }

// Función opcional para guardar los cambios
 async guardarCambios(modelo: any) {
    // Aquí puedes hacer una llamada a tu API o servicio para guardar los cambios
    // Por ejemplo:
    const res =  <any>await this.campaignService.actualizarCantidad(modelo.name, modelo.cantidad, this.date, this.tipo);
    if (res.success) {
     
    } 
}



  
getTotalMensajesEnviados(): number {
  return this.reporte_modelos.reduce((acc, modelo) => acc + modelo.mensajes_enviados, 0);
}
getTotalLeadsObtenidos(): number {
  return this.reporte_modelos.reduce((acc, modelo) => acc + modelo.leads_obtenidos, 0);
}

getTotalConversion(): number {
  const totalMensajes = this.getTotalMensajesEnviados();
  const totalLeads = this.getTotalLeadsObtenidos();
  return totalMensajes ? (totalLeads / totalMensajes) * 100 : 0;
}

getTotalCPL(): number {
  const totalLeads = this.getTotalLeadsObtenidos();
  const totalCost = this.reporte_modelos.reduce((acc, modelo) => acc + modelo.cpl * modelo.leads_obtenidos, 0);
  return totalLeads ? totalCost / totalLeads : 0;
}

getTotalLogro(): number {
  return this.reporte_modelos.reduce((acc, modelo) => acc + modelo.logro, 0);
}

}



import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-programar-llamada-semi-nuevi',
  templateUrl: './modal-programar-llamada-semi-nuevi.component.html',
  styleUrls: ['./modal-programar-llamada-semi-nuevi.component.scss']
})
export class ModalProgramarLLamadaSemiNueviComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CampaignService } from '../shared/services/campaign.service';
import { lastValueFrom, BehaviorSubject} from 'rxjs'
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgbActiveModal, NgbModal, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { DecimalPipe } from '@angular/common';


import Swal from 'sweetalert2';
@Component({
  selector: 'app-loyalty-campaigns-sms',
  templateUrl: './loyalty-campaigns-sms.component.html',
  styleUrls: ['./loyalty-campaigns-sms.component.scss']
})
export class LoyaltyCampaignsSmsComponent implements OnInit {

  protected campaigns = [ ]

  // ARRAY CON FILTROS
  filtros

  saldo
  saldo_mes
  moneda

  buttonFiltrarTexto
  isCollapsed
  default_values

  constructor(
    private campaignService: CampaignService,
    private http: HttpClient,
    private decimalPipe: DecimalPipe,
    private NgbTooltipModule: NgbTooltipModule,
    private cdr: ChangeDetectorRef

  ) { 

    this.filtros = {}

    if (localStorage.getItem("campaigns_sms_filtros")) {
      this.filtros = JSON.parse(localStorage.getItem("campaigns_sms_filtros"))
    }
  
    this.default_values = {}


  }

  
  formatDecimal(value: number): string {
    return this.decimalPipe.transform(value, '1.2-2')!;
  }

  formatNumber(value: number): string {
    return this.decimalPipe.transform(value, '1.0-0')!;
  }


  detallado_campaign = {
    marketing: 0,
    planning:0,

    remarketing: 0,
    recompra: 0,
    referido: 0,
    encuesta: 0,
    bienvenida: 0,
    brochure: 0,
    total: 0,
    otros: 0,
  }

  detallado_envio = {
    marketing: 0,
    planning:0,
    remarketing: 0,
    recompra: 0,
    referido: 0,
    encuesta: 0,
    bienvenida: 0,
    brochure: 0,
    total: 0,
    otros: 0,
  }
  
  
  async ngOnInit() {

    this.establecerMesAnioActual();
    this.setearFechas();


    this.load_values();
    this.load_campaigns();

  }



  fechaMin: string;
  fechaMax: string;

  establecerMesAnioActual() {
    const hoy = new Date();
    const anio = hoy.getFullYear();
    const mes = String(hoy.getMonth() + 1).padStart(2, '0'); // Formato 'YYYY-MM'
    this.filtros.mesAnio = `${anio}-${mes}`;
  }

  setearFechas() {
    const mesAnio = this.filtros.mesAnio;
    if (mesAnio) {
      const [anio, mes] = mesAnio.split('-').map(Number);
      const primerDiaMes = new Date(anio, mes - 1, 1);
      const ultimoDiaMes = new Date(anio, mes, 0);
      
      // Obtener el año y mes actual
      const fechaActual = new Date();
      const anioActual = fechaActual.getFullYear();
      const mesActual = fechaActual.getMonth() + 1; // getMonth() devuelve el mes en base 0
  
      this.filtros.fecha_inicio = primerDiaMes.toISOString().split('T')[0];
  
      if (anio === anioActual && mes === mesActual) {
        // Si es el mes y año actual, usar la fecha actual
        this.filtros.fecha_fin = fechaActual.toISOString().split('T')[0];
      } else {
        // De lo contrario, usar el último día del mes
        this.filtros.fecha_fin = ultimoDiaMes.toISOString().split('T')[0];
      }
  
      this.filtros.fecha_lanzamiento_inicio = '';
      this.filtros.fecha_lanzamiento_fin = '';
  
      // Actualizar los campos de fecha con el rango del mes seleccionado
      this.fechaMin = this.filtros.fecha_inicio;
      this.fechaMax = this.filtros.fecha_fin;
  
      this.updateDateInputs();
    }
  }
  
  updateCalendar() {
    this.setearFechas();
  }

  isCollapsedDeatils: boolean = true;

  updateDateInputs() {
    const inputs = document.querySelectorAll('input[type="date"]');
    inputs.forEach(input => {
      (input as HTMLInputElement).setAttribute('min', this.fechaMin);
      (input as HTMLInputElement).setAttribute('max', this.fechaMax);
    });
  }


  rerender(): void {


    localStorage.setItem("campaigns_sms_filtros", JSON.stringify(this.filtros))


    this.buttonFiltrarTexto = `Cargando...`
    this.load_campaigns();

    this.buttonFiltrarTexto = "Filtrar"

  }



  async load_values() {

    const res = <any>await this.campaignService.campaign_service_init_general('SMS');

    if (res.success) {

      this.default_values.type = res.payload.type
      this.default_values.tipo_filtro = res.payload.tipo_filtro
    }

    this.buttonFiltrarTexto = "Filtrar"

  }
  costo_total ;

  reporte;

  
  reporte_leads;
  reporte_leads_recompra;
  reporte_leads_brochure;
  reporte_leads_modelos;
  reporte_leads_recompra_modelos;
  reporte_leads_brochure_modelos;

  protected Object = Object;

  private resetData(): void {
    this.campaigns = [];
    this.reporte_leads = [];
    this.reporte_leads_recompra = [];
    this.reporte_leads_brochure = [];
    this.reporte_leads_modelos = [];
    this.reporte_leads_recompra_modelos = [];
    this.reporte_leads_brochure_modelos = [];
  }

  private updateCampaignData(res: any): void {
  
    // Actualizar datos de reportes
    this.reporte_leads = res.datos_rmkt.data;
    this.reporte_leads_recompra = res.datos_recompra.data;
    this.reporte_leads_brochure = res.datos_brochure.data;
    this.reporte_leads_modelos = res.datos_rmkt.modelos;
    this.reporte_leads_recompra_modelos = res.datos_recompra.modelos;
    this.reporte_leads_brochure_modelos = res.datos_brochure.modelos;

  }

  reporte_view: boolean = false;
  cancel_campaign_view: boolean = false;

  async load_campaigns() {
    Swal.showLoading()
    this.resetData();


    const res = <any>await this.campaignService.get_campaign_filter(this.filtros, "SMS");

    if (res.success) {

      this.campaigns = res.campaigns;
      this.cancel_campaign_view = res.cancel_campaign_view;

      this.reporte_view = res.reporte_view;

      this.costo_total = parseFloat(res.costo_total).toFixed(2) ;

      this.updateCampaignData(res);


      const response_saldo = <any>await this.campaignService.campaign_service_saldo_by_filter(this.filtros, "sms");

      if (response_saldo.success) {
        this.moneda = response_saldo.moneda;

        this.saldo_mes = response_saldo.saldo_mes;
        var calculo_saldo = this.saldo_mes - this.costo_total;
        this.saldo = calculo_saldo.toFixed(2) 
      }
   
      this.reporte = res.reporte.por_tipo;

      // Marca cambios en la vista
      this.cdr.detectChanges();


    }
    Swal.close()
  }






  editarSaldo() {
    Swal.fire({
      title: 'Editar Saldo',
      input: 'number',
      inputLabel: 'Ingrese el nuevo saldo',
      inputValue: this.saldo_mes,
      showCancelButton: true,
      confirmButtonText: 'Actualizar',
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        if (!value) {
          return 'Debe ingresar un número válido';
        }
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const saldo = parseFloat(result.value);
        const mesAnio = this.filtros.mesAnio;

        if (this.guardar_saldo(saldo, mesAnio, "sms")) {
          Swal.fire('Actualizado!', '', 'success');
          this.load_campaigns();

        }

      }
    });
  }

  async guardar_saldo (saldo, mesAnio, type){
    const res =  <any>await this.campaignService.insert_saldo_mes(saldo, mesAnio, type);
    if (res.success) {
      return true;
    } else {
      return false;
    }
  }


    async desactivarCampaign(campaignId: number, status: string) {
      console.log("inicia función");
    
      const result = await Swal.fire({
        title: '¿Está seguro?',
        text: 'Desea desactivar esta campaña',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí, desactivar',
        cancelButtonText: 'Cancelar'
      });
    
      if (result.isConfirmed) {
        try {
          const res = await this.campaignService.desactivarCampaign(campaignId, status, "SMS");
          
          if (res.success) {
            Swal.fire({
              icon: 'success',
              title: 'Campaña desactivada',
              text: res.message || 'La campaña se desactivó correctamente'
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: res.message || 'No se pudo desactivar la campaña'
            });
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Ocurrió un error al desactivar la campaña'
          });
        }
      }
    }

}
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, lastValueFrom, timer } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { Location } from "@angular/common"
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalLlamadasTelefonicasDetailSemiNuevosComponent } from '../modal-llamadas-telefonicas-detail-semi-nuevos/modal-llamadas-telefonicas-detail-semi-nuevos.component';
import { ModalCorreoDetailComponent } from '../modal-correo-detail/modal-correo-detail.component';


@Component({
  selector: 'app-history-comunication-semi-nuevos',
  templateUrl: './history-comunication-semi-nuevos.component.html',
  styleUrls: ['./history-comunication-semi-nuevos.component.scss']
})
export class HistoryComunicationSemiNuevosComponent implements OnInit, AfterViewInit {

  private routeSub: Subscription;
  private tienda_id
  private lead_id
  private jwt

  lead : any

  calls = []
  mails = []
  whatsapps = []


  inicial_vendedor
  inicial_cliente

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private location: Location,
    private modalService: NgbModal,
  ) {

    this.routeSub = this.route.params.subscribe(parametros => {
      this.tienda_id = parametros.tienda_id
      this.lead_id = parametros.lead_id
    })

    this.jwt = JSON.parse(localStorage.user).jwt

    this.inicial_vendedor = JSON.parse(localStorage.user).nombres.charAt(0)
  }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {


    const data = new FormData()
    data.append('lead_id', this.lead_id)
    data.append('tienda_id', this.tienda_id)
    data.append('comunicacion_type', `Whatsapp`)
    data.append('jwt', JSON.parse(localStorage.user).jwt)
    const response = <any>await lastValueFrom(this.http.post(`${environment.url_api}LeadAPISemiNuevo/get_history_of_comunications_by_lead_id`, data))
    if (response.success) {
      this.calls = response.history.calls
      this.mails = response.history.mailing
      this.whatsapps = response.history.whatsapp

      this.lead = response.lead
      this.inicial_cliente = this.lead.nombres.charAt(0)
    }
  }

  go_back() {
    this.location.back()
  }

  show_info_call(id) {
    const dialogRef = this.modalService.open(ModalLlamadasTelefonicasDetailSemiNuevosComponent, {
      size: 'lg'
    })
    dialogRef.componentInstance.comunicacion_id = id
    dialogRef.componentInstance.jwt = this.jwt

    dialogRef.result.then((data) => {
      console.log(data)
    })
  }

  show_info_mail(id) {
    const dialogRef = this.modalService.open(ModalCorreoDetailComponent, {
      size: 'lg'
    })
    dialogRef.componentInstance.comunicacion_id = id
    dialogRef.componentInstance.jwt = this.jwt

    dialogRef.result.then((data) => {
      console.log(data)
    })
  }



}

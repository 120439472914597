
import { Component, OnInit } from '@angular/core';
import { SemiNuevoService } from '../semi-nuevo.service';
import Swal from 'sweetalert2';  // Importa SweetAlert
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, lastValueFrom, timer } from 'rxjs';
import { Console } from 'console';
import { DecimalPipe } from '@angular/common';


@Component({
  selector: 'app-edit-modelo-semi-nuevo',
  templateUrl: './edit-modelo-semi-nuevo.component.html',
  styleUrls: ['./edit-modelo-semi-nuevo.component.scss'],
  providers: [DecimalPipe],

})
export class EditModeloSemiNuevoComponent implements OnInit {
  private routeSub: Subscription;

  constructor(private SemiNuevoService: SemiNuevoService,
              private route: ActivatedRoute,
              private router: Router,
              private decimalPipe: DecimalPipe,
            ) {}

  jwt: string;
  categorias = [];
  size = [];
  tipo = [];
  is_loading = false;
  attributes = [];

  vehiculo_a_crear = {
    concesionario_id: '',
    active: 1, 
    nombre: "",
    precio: 0,
    anio:'',
    tipo: "",
    color: "",
    km: 0,
    combustible: "",
    motor: "",
    traccion: "",
    transmision: "",
    placa: "",
    unicoDueno: false,
    specs: [], 
    legal: "",
    fotos: [],
    imagen: ""
  };

  opcionesTipo = [];
  opcionesCombustible = [];
  opcionesMotor = [];
  opcionesTraccion = [];
  opcionesTransmision = [];
  dealers = [];


  tienda_selected;
  producto_id;

  ngOnInit(): void {
  

    this.routeSub = this.route.params.subscribe(parametros => {

      if (isNaN(parametros.tienda_id) || parametros.tienda_id <= 0) {
        this.router.navigate(['/tiendas-modelos-semi-nuevos']);
      }

      this.tienda_selected = parametros.tienda_id

      if (isNaN(parametros.id) || parametros.id <= 0) { // Verifica si no es un número válido
        this.router.navigate(['/modelos-semi-nuevos/' + this.tienda_selected]); // Redirige a otra ruta
      }

      this.producto_id = parametros.id
      this.vehiculo_a_crear.concesionario_id = this.tienda_selected;

      this.init_producto_by_id(this.producto_id);

    })


    this.init_service();
  }

  precioEnSoles: number = 0; // Precio en soles
  tasaDeCambio: number = 0; // Ejemplo: 1 USD = 3.75 PEN

  convertirADolaresASoles(): void {
    console.log(this.vehiculo_a_crear.precio);
    
    // Verificar si el precio es null o undefined
    if (this.vehiculo_a_crear.precio == null) { // Usa 'null' en minúsculas
      this.vehiculo_a_crear.precio = 0;
    }
    
    const conversion = this.vehiculo_a_crear.precio * this.tasaDeCambio;
    this.precioEnSoles = parseFloat(conversion.toFixed(2));
  }


  async init_producto_by_id(id) {

    const res = <any>await this.SemiNuevoService.get_product_by_id(id, this.tienda_selected);


    if (res.success) {

      this.mainImageIndex = res.main;
      
      this.vehiculo_a_crear = {
        concesionario_id: res.modelo.concesionario_id, 
        active: res.modelo.active, 
        nombre: res.modelo.nombre, 
        precio: parseFloat(res.modelo.price), 
        anio: res.modelo.year,
        tipo: res.modelo.type,
        color: res.modelo.color, 
        km: parseInt(res.modelo.km), 
        combustible: res.modelo.combustible, 
        motor: res.modelo.motor, 
        traccion:res.modelo.traccion, 
        transmision: res.modelo.transmision, 
        placa: res.modelo.placa, 
        unicoDueno: res.modelo.unique_owner === "true",
        specs: res.speecs,
        legal: res.modelo.legal,
        fotos: res.images,
        imagen: res.modelo.imagen,
    };

    if (res.modelo.imagen) {
      this.imagePreview = res.modelo.imagen;
    }
  

    if (this.vehiculo_a_crear.specs.length == 0) {
      this.addSpec();
    }
    
    this.convertirADolaresASoles();


    } else {
      this.router.navigate(['/modelos-semi-nuevos/' + this.tienda_selected]); // Redirige a otra ruta
    }
  }


  atributosExtra: any[]; // Lista para almacenar selects adicionales

  async init_service() {
    this.jwt = JSON.parse(localStorage.user).jwt;
    const res = <any>await this.SemiNuevoService.lead_service_init_general(this.jwt);
    if (res.success) {

      this.opcionesTipo = res.payload.Tipo;
      this.opcionesCombustible = res.payload.Combustible;
      this.opcionesMotor = res.payload.Motor;
      this.opcionesTraccion = res.payload.Traccion;
      this.opcionesTransmision = res.payload.Transmision;
      this.tasaDeCambio = res.payload.tasaDeCambio;

      this.dealers = res.payload.campus;

      this.convertirADolaresASoles();


      if (!this.dealers.some(dealer => dealer.concesionario_id == this.tienda_selected)) {
        this.router.navigate(['/tiendas-modelos-semi-nuevos']);
      } 
    }
  }


  eliminarSpec(index: number): void {
    this.vehiculo_a_crear.specs.splice(index, 1);
  }

  validateFields(): boolean {
    if (!this.vehiculo_a_crear.nombre) {
      Swal.fire('Error', 'El nombre es obligatorio.', 'error');
      return false;
    }
    if (this.vehiculo_a_crear.precio <= 0) {
      Swal.fire('Error', 'El precio debe ser mayor a cero.', 'error');
      return false;
    }
    if (!this.vehiculo_a_crear.tipo) {
      Swal.fire('Error', 'El tipo de vehículo es obligatorio.', 'error');
      return false;
    }
    return true;
  }

   
  formatDecimal(value: number): string {
    return this.decimalPipe.transform(value, '1.2-2')!;
  }

  formatNumber(value: number): string {
    return this.decimalPipe.transform(value, '1.0-0')!;
  }

  async onSubmit(): Promise<void> {
    if (this.validateFields()) {
      this.is_loading = true;

      console.log(this.vehiculo_a_crear.fotos);

      const formData = new FormData();
      formData.append('jwt', JSON.parse(localStorage.getItem("user")).jwt);
      formData.append('active', this.vehiculo_a_crear.active.toLocaleString());
      formData.append('concesionario_id', this.vehiculo_a_crear.concesionario_id);

      formData.append('producto_id', this.producto_id);
      formData.append('nombre', this.vehiculo_a_crear.nombre);
      formData.append('precio', this.vehiculo_a_crear.precio.toString());
      formData.append('anio', this.vehiculo_a_crear.anio?.toString() || "");
      formData.append('tipo', this.vehiculo_a_crear.tipo);
      formData.append('color', this.vehiculo_a_crear.color);
      formData.append('km', this.vehiculo_a_crear.km.toString());
      formData.append('combustible', this.vehiculo_a_crear.combustible);
      formData.append('motor', this.vehiculo_a_crear.motor);
      formData.append('traccion', this.vehiculo_a_crear.traccion);
      formData.append('transmision', this.vehiculo_a_crear.transmision);
      formData.append('placa', this.vehiculo_a_crear.placa);
      formData.append('unicoDueno', this.vehiculo_a_crear.unicoDueno.toString());
      formData.append('legal', this.vehiculo_a_crear.legal);
    
      formData.append('specs',  JSON.stringify(this.vehiculo_a_crear.specs));


      formData.append('elimino_imagen', this.elimino_imagen ? 'si' : 'no');

      if (this.vehiculo_a_crear.imagen) {
        formData.append('imagen',  this.vehiculo_a_crear.imagen);
      }

      console.log(this.vehiculo_a_crear.fotos);

      formData.append('fotos_enviadas',  JSON.stringify(this.vehiculo_a_crear.fotos));


      formData.append('fotos_enviadas', JSON.stringify(this.vehiculo_a_crear.fotos));

      this.vehiculo_a_crear.fotos.forEach((foto, index) => {
          if (!foto.crm) {
              // Adjuntar archivo y su ID al FormData
              formData.append(`fotos[${index}][file]`, foto.file);
              formData.append(`fotos[${index}][id]`, foto.id);
          }
      });
      
      
    
      // Agregar índice de la imagen principal
      if (this.mainImageIndex !== null) {
        formData.append('mainImageIndex', this.mainImageIndex.toString());
      }
      
      try {
        const res = <any> await this.SemiNuevoService.editProduct(formData);
        this.is_loading = false;

        if (res.success) {
          Swal.fire({
            title: '¡Vehículo Editado!',
            text: 'El vehículo se ha editado exitosamente.',
            icon: 'success',
            confirmButtonText: 'OK'
          });
        } else {
          throw new Error('Error en la respuesta del servidor');
        }
      } catch (error) {
        this.is_loading = false;
        Swal.fire({
          title: 'Error',
          text: 'No se pudo editar el vehículo. Inténtalo nuevamente.',
          icon: 'error',
          confirmButtonText: 'Entendido'
        });
      }

    }
  }

  onCancel(): void {
    Swal.fire('Cancelado', 'La creación del vehículo ha sido cancelada.', 'info');
    this.router.navigate(['/modelos-semi-nuevos/' + this.tienda_selected]);
  }


    specsInput = ''; 
  
    updateSpecs() {
      const specs = this.specsInput
        .split(',')
        .map((spec) => spec.trim())
        .filter((spec) => spec.length > 0);
      this.vehiculo_a_crear.specs = specs;
    }
  

  


  mainImageIndex: number | null = null; // Índice de la imagen principal

  onFotosSelected(event: any) {
    const files: FileList = event.target.files;
  
    if (files.length + this.vehiculo_a_crear.fotos.length > 6) {
      Swal.fire(
        'Demasiadas imágenes',
        'Solo puedes agregar hasta 6 imágenes.',
        'error'
      );
      return;
    }
  
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
  
      if (this.vehiculo_a_crear.fotos.length < 6) {
        const reader = new FileReader();
        reader.onload = () => {

          const uniqueId = `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`; // Generar ID único

          this.vehiculo_a_crear.fotos.push({
            file, // Guardar archivo original
            url: reader.result as string, // Guardar vista previa
            isMain: false, // Por defecto, no es principal
            crm: false,
            id: uniqueId, // Asignar el ID único

          });
        };
        reader.readAsDataURL(file);
      }
    }
  }
  
  toggleMainImage(index: number): void {
    const image = this.vehiculo_a_crear.fotos[index];

    // Alterna el estado de la imagen seleccionada
    if (image.isMain) {
        // Si ya es principal, deselecciónala
        image.isMain = false;
        this.mainImageIndex = null; // Sin imagen principal
    } else {
        // Si no es principal, selecciona esta y deselecciona otras
        this.vehiculo_a_crear.fotos.forEach((foto) => foto.isMain = false);
        image.isMain = true;
        this.mainImageIndex = index;
    }
}
  
  moveImage(index: number, direction: 'up' | 'down') {
    const targetIndex = direction === 'up' ? index - 1 : index + 1;
    if (targetIndex < 0 || targetIndex >= this.vehiculo_a_crear.fotos.length) {
      return; // No mover si está fuera de rango
    }
  
    // Intercambiar imágenes
    const temp = this.vehiculo_a_crear.fotos[index];
    this.vehiculo_a_crear.fotos[index] = this.vehiculo_a_crear.fotos[targetIndex];
    this.vehiculo_a_crear.fotos[targetIndex] = temp;
  
    // Actualizar el índice de la imagen principal si se mueve
    if (this.mainImageIndex === index) {
      this.mainImageIndex = targetIndex;
    } else if (this.mainImageIndex === targetIndex) {
      this.mainImageIndex = index;
    }
  }
  
  removeImage(index: number) {
    this.vehiculo_a_crear.fotos.splice(index, 1); // Eliminar imagen
    if (this.mainImageIndex === index) {
      this.mainImageIndex = null; // Si era principal, reiniciar
    } else if (this.mainImageIndex !== null && this.mainImageIndex > index) {
      this.mainImageIndex--; // Ajustar el índice de la principal si es necesario
    }
  }
        
  
  addSpec() {
    const uniqueId = `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`; // Generar ID único

    this.vehiculo_a_crear.specs.push({ value: '', id: uniqueId, index: this.vehiculo_a_crear.specs.length });
  }

  removeSpec(index: number) {
    this.vehiculo_a_crear.specs.splice(index, 1);
  }

  moveSpecUp(index: number) {
    if (index > 0) {
      const temp = this.vehiculo_a_crear.specs[index];
      this.vehiculo_a_crear.specs[index] = this.vehiculo_a_crear.specs[index - 1];
      this.vehiculo_a_crear.specs[index - 1] = temp;
    }
  }

  moveSpecDown(index: number) {
    if (index < this.vehiculo_a_crear.specs.length - 1) {
      const temp = this.vehiculo_a_crear.specs[index];
      this.vehiculo_a_crear.specs[index] = this.vehiculo_a_crear.specs[index + 1];
      this.vehiculo_a_crear.specs[index + 1] = temp;
    }
  }


  
      imagePreview;
  
      onFileSelected(event: any) {
        const file = event.target.files[0];
  
        if (!file) {
            return;
        }
  
        const maxSizeInMB = 2;
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
  
        if (file.size > maxSizeInBytes) {
            Swal.fire('Archivo muy grande', `El archivo debe ser menor a ${maxSizeInMB}MB`, 'error');
            return;
        }
  
        const validTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'];
        if (!validTypes.includes(file.type)) {
            Swal.fire('Tipo de archivo no permitido', 'Solo se permiten imágenes (PNG, JPG, GIF)', 'error');
            return;
        }
  
        const reader = new FileReader();
        reader.onload = () => {
            this.imagePreview = reader.result;
        };
        reader.readAsDataURL(file);
  
        this.vehiculo_a_crear.imagen = file;
    }


    elimino_imagen: boolean = false;

  
    clearImage(): void {
  
      this.imagePreview = null;
      this.elimino_imagen = true;

  
      const fileInput = document.getElementById('imagenProducto') as HTMLInputElement;
        if (fileInput) {
          fileInput.value = '';
      }
    }
    

}


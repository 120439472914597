<app-breadcrumb [title]="'Agregar campaña - Whatsapp'" [items]="['Campañas', 'Agregar']"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label for="">Nombre de campaña</label>
                                <input class="form-control" type="text" placeholder="" [(ngModel)]="campaign.name">
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label for="">Seleccione audiencia</label>
                                <ng-select (change)="get_audience($event)" bindValue="id" bindLabel="name"
                                    [items]="list_of_audiences" class="js-example-basic-multiple"
                                    [(ngModel)]="campaign.audience_id">
                                </ng-select>
                            </div>
                        </div>


                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label for="">Indica fecha inicio de Campaña</label>
                                <input class="form-control" type="datetime-local" placeholder=""
                                    [(ngModel)]="campaign.fecha_lanzamiento" [min]="minDate()">
                            </div>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label for="">Seleccione el tipo de envio</label>
                                <ng-select (change)="pre_validate_merges()" bindValue="id" bindLabel="name"
                                    [items]="type_of_envio" class="js-example-basic-multiple"
                                    [(ngModel)]="campaign.type">
                                </ng-select>
                            </div>
                        </div>


                        <div class="col-12 col-md-12">
                            <div class="form-group">
                                <label for="">Seleccione la plantilla</label>
                                <ng-select (change)="pre_validate_merges()" bindValue="id" bindLabel="name"
                                    [items]="templates" class="js-example-basic-multiple">
                                </ng-select>
                            </div>
                        </div>

                        <div class="col-12 col-md-6" *ngIf="campaign.type == 3">
                            <div class="form-group">
                                <label for="">Seleccione la encuesta</label>
                                <ng-select (change)="pre_validate_merges()" bindValue="id" bindLabel="name"
                                    [items]="list_of_encuestas" class="js-example-basic-multiple"
                                    [(ngModel)]="campaign.encuesta_id">
                                </ng-select>
                            </div>
                        </div>

                        <div class="form-group">
                            <div class="buttons">
                                <div *ngIf="merges.length > 0" class="m-t-40">
                                    <h5>Campos dinámicos: </h5>
                                    <div class="row m-t-30">
                                        <div *ngFor="let merge of merges; let i = index"
                                            class="col-12 m-t-20 campo_merge" [ngClass]="{'active': merge.validate}">

                                            <h6 class="m-b-20">Campo dinámico: <b>{{merges[i].name}}</b> </h6>
                                            
                                            <p>{{merges[i].type}}</p>

                                            <div class="form-group">
                                                <label for="">Categoría de contenido</label>
                                                <ng-select bindValue="id" bindLabel="name"
                                                    (change)="pre_validate_merges()" [(ngModel)]="merges[i].type"
                                                    [items]="type_discriminacion_merges"
                                                    class="js-example-basic-multiple">
                                                </ng-select>

                                            </div>
                                            <div class="form-group" *ngIf="merges[i].type == 2">
                               
                                                <div class="row" *ngFor="let model of list_of_models; let j = index">
                                                    <div class="form-group row">
                                                        <label class="col-12 col-md-12"
                                                            for=""><u><b>{{model.modelo}}</b></u>
                                                        </label>

                                                        <div class="col-12 col-md-9">
                                                            
                                                            <input *ngIf="merges[i].subtype == 1" class="form-control"
                                                                type="text" [(ngModel)]="merges[i].items[j].contenido"
                                                                (ngModelChange)="pre_validate_merges()" placeholder="">

                                                        </div>
                                                    </div>


                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div *ngIf="campaign.template != null" class="m-t-50">
                                    <h4>Preview del template</h4>
                                    <small>(Únicamente se muestran como máximo 10 personas)</small>
                                    <div class="form-group">
                                        <div class="row">
                                            <div class="col-9">
                                                <label for="">Seleccione una persona</label>
                                                <ng-select bindValue="id" bindLabel="nombres" [items]="test_audience"
                                                    [(ngModel)]="person_selected" class="js-example-basic-multiple">
                                                </ng-select>
                                            </div>
                                            <div class="col-3">
                                                <button (click)="ver_preview()" class="btn-primary btn m-t-30">Ver
                                                    preview</button>
                                            </div>
                                        </div>


                                    </div>
                                    <!-- <iframe [srcdoc]="template_preview" width="100%" height="600px"></iframe> -->
                                </div>
                            </div>
                        </div>
                        <div class="buttons">
                            <button (click)="addCampaign()" class="btn btn-secondary">Agregar campaña</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>